.c-content-index {
	padding-bottom: 60px;

	&__body {
		@mixin flexible-width-body;
		margin: 0 auto;
	}

	&__card-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 20px;
	}

	&__card-item {
		// 💉 最後の-1pxはIE11のカラム落ち対策
		flex-basis: calc(1 / $card-cols * (100% - $card-cols-side-margin * ($card-cols - 1)) - 1px); // stylelint-disable-line declaration-property-value-whitelist
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: $card-cols-vertical-margin;
		margin-right: $card-cols-side-margin;
		position: relative;
		overflow: hidden;

		@media (--sm) {
			// 💉 最後の-1pxはIE11のカラム落ち対策
			flex-basis: calc(1 / $card-cols-sm * (100% - $card-cols-side-sm-margin * ($card-cols-sm - 1)) - 1px); // stylelint-disable-line declaration-property-value-whitelist
			margin-bottom: $card-cols-vertical-sm-margin;
			margin-right: $card-cols-side-sm-margin;
		}

		@media (--xs) {
			// 💉 最後の-1pxはIE11のカラム落ち対策
			flex-basis: calc(1 / $card-cols-xs * (100% - $card-cols-side-xs-margin * ($card-cols-xs - 1)) - 1px); // stylelint-disable-line declaration-property-value-whitelist
			margin-bottom: $card-cols-vertical-xs-margin;
			margin-right: $card-cols-side-xs-margin;
		}

		&::before {
			background: $green-color06;
			content: "";
			display: block;
			font-size: calc(14 / 16 * 1em);
			font-weight: bold;
			width: 62px;
			height: 62px;
			left: -38px;
			padding: 50px 0 0;
			position: absolute;
			text-align: center;
			top: -38px;
			transform: rotate(-45deg);
			z-index: 1;
		}

		&:nth-child($(card-cols)n) {
			margin-right: 0;
		}
	}
}
