.c-hero {
	width: 100%;
	text-align: center;
	background: url("/img/home/bg-hero.jpg") no-repeat center center / cover;
	height: 760px;
	display: flex;
	align-items: center;

	@media (--sm-lte) {
		height: auto;
		padding: 70px 0 66px;
	}

	&__message {
		position: relative;
		width: 460px;
		margin: 0 auto;

		@media (--sm-lte) {
			width: calc(7 / 10 * 100%);
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $lightest-color url("../img/home/bg-hero-message.png") no-repeat center bottom / 100% auto;
			opacity: .9;
		}

		figure {
			margin: 0 auto;
		}

		img {
			width: 100%;
		}

		&-body {
			position: relative;
			padding: 40px 60px 30px;
			text-align: center;

			@media (--sm-lte) {
				background-size: contain;
				padding: 2em 2em 1.5em;

			}
		}

		&-main {
			padding: 0 10px;
			margin-bottom: 25px;

			@media (--sm-lte) {
				margin-bottom: .5em;
				padding: 0 .6em;
			}
		}

		&-sub {
			margin-bottom: 25px;

			@media (--sm-lte) {
				margin-bottom: .5em;
				padding: 0 .5em;
			}
		}

		&-text {
			padding: 0 10px;
			font-size: calc(15 / 16 * 1em);
			width: 100%;
			margin-bottom: 20px;

			@media (--sm-lte) {
				margin-bottom: .5em;
				padding: 0;
			}
		}

		&-btn {
			position: relative;
			z-index: 10;

			@media (--sm-lte) {
				figure {
					margin-bottom: 0;
				}
			}

			a {
				display: inline-block;
				transition: opacity 100ms;

				&:hover {
					opacity: .7;
				}
			}

			span.nolink {
				display: inline-block;
				transition: opacity 100ms;

				&.nolink {
					pointer-events: none;
				}
			}

			img {
				vertical-align: bottom;
			}
		}
	}

	&__icon01 {
		position: absolute;
		top: -86px;
		left: -143px;

		@media (--sm-lte) {
			top: -35px;
			left: -75px;
		}

		img {
			@media (--sm-lte) {
				width: 141px;
			}
		}
	}

	&__icon02 {
		position: absolute;
		bottom: -87px;
		right: -75px;

		@media (--sm-lte) {
			bottom: -42px;
			right: -30px;
		}

		img {
			@media (--sm-lte) {
				width: 120px;
			}
		}
	}

	.c-page-home__message {
		&-main {
			@media (--sm-lte) {
				width: calc(328 / 460 * 100%);
				margin: 0 auto 10px;
			}

			figure {
				@media (--sm-lte) {
					margin: 0;
				}
			}

			img {
				@media (--sm-lte) {
					width: auto;
					max-width: 100%;
				}
			}
		}

		&-sub {
			@media (--sm-lte) {
				width: calc(338 / 460 * 100%);
				margin: 0 auto;
			}

			figure {
				@media (--sm-lte) {
					margin: 0;
				}
			}

			img {
				@media (--sm-lte) {
					width: auto;
					max-width: 100%;
				}
			}
		}

		&-text {
			margin: 0 20px;

			@media (--sm-lte) {
				margin: 0 18px;
			}

			p {
				@media (--sm-lte) {
					font-size:1rem;
				}
			}
		}

		&-btn {
			position: relative;
			z-index: 1;

			@media (--sm-lte) {
				width: calc(342 / 460 * 100%);
				margin: 0 auto;
			}

			figure {
				@media (--sm-lte) {
					margin: 0;
				}
			}

			img {
				@media (--sm-lte) {
					width: auto;
					max-width: 100%;
				}
			}

		}
	}
}
