.c-nav-breadcrumb {
	font-size: 1.6rem;
	max-width: 1000px;
	padding: 30px 0 0;

	@media (--md-lte) {
		display: none;
	}

	ol {
		margin: 0;
		padding: 0;

		> li {
			list-style: none;
			font-size: calc(14 / 16 * 1em);

			&::after {
				content: " | ";
				display: inline-block;
				margin: 0 10px 0 5px;
				color: $secondary-color-darker;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	a {
		color: $secondary-color;
		display: inline-block;
		text-decoration: underline;
	}

	&__item {
		display: inline-block;
		margin: 0;
		padding: 0;
	}

	&__item[data-breadcrumb="current"] {
		a {
			text-decoration: none;
		}
	}
}
