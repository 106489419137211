.c-page-sub {
	&__content {
		margin: 0 auto;

		@media (--sm-lte) {
			// margin: 0 auto 80px;
		}
	}

	&__content-title {
		@media (--sm-lte) {
			margin-bottom: 20px;
		}
	}

	&__breadcrumb {
		width: 1060px;
		min-height: 110px;
		margin: -110px auto 0;
		padding: 0 50px 50px;
		background: $lightest-color;

		@media (--sm-lte) {
			width: 100%;
			height: auto;
			margin: 0 auto;
			display: none;
		}
	}

	&__content-detail {
		width: 960px;
		margin: 0 auto;
		padding: 0 0 80px;

		@media (--sm-lte) {
			width: 100%;
			padding: 20px 5% 40px;
		}

		.extra-index {

			@media (--sm-lte) {
			margin: 0 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				margin-left: -20px;
				padding: 0;

				@media (--sm-lte) {
					margin: 0 0 20px;
				}

				a {
					color: currentColor;
					display: flex;
					flex-direction: column;
					height: 100%;
					text-decoration: none;
					width: 100%;

					&:hover {
						opacity: .6;
					}
				}

			li {
				list-style:  none;
				margin: 0 0 20px 20px;
				border: 8px solid $gray-color06;
				position: relative;
				width: calc(100% / 3 - 20px);

				@media (--sm-lte) {
					width: 100%;
					margin: 0 0 20px;
				}

				&::before {
					border-bottom: 30px solid transparent;
					border-left: 30px solid $green-color06;
					content: "";
					height: 30px;
					left: -8px;
					position: absolute;
					top: -8px;
					width: 30px;
					z-index: 100;
				}

				h3 {
					background: none;
					color: $secondary-color;
					font-size: calc(18 / 16 * 1em);
					font-weight: bold;
					margin: 30px;
					position: relative;
					text-align: center;

					&::after {
						position: absolute;
						bottom: -15px;
						left: 0;
						z-index: 2;
						content: "";
						width: calc(100% * .2);
						height: 1px;
						right: 0;
						margin: 0px auto;
						background-color: $secondary-color;
					}
				}

				p {
					margin: 30px;
					text-align: center;

					@media (--sm-lte) {
						margin: 20px;
					}

					&.btn {
						margin-top: auto;
						span {
							background: $secondary-color;
							border-radius: 30px;
							color: $lightest-color;
							display: inline-block;
							font-size: calc(14 / 16 * 1em);
					    min-width: 160px;
							padding: 8px 20px;
							position: relative;
					    text-decoration: none;

							&::after {
								border: 2px solid currentColor;
								border-width: 2px 2px 0 0;
								content: "";
								display: block;
								height: 8px;
								margin-top: -4px;
								position: absolute;
								right: 1.3em;
								top: 50%;
								transform: rotate(45deg);
								width: 8px;
							}
						}
					}
				}

					img {
						height: auto;
				    max-width: 100%;
				    width: auto;
				    z-index: 100;
						flex-shrink: 0;
					}
				}
			}
		}

		.extra-btn-index {

			@media (--sm-lte) {
			margin: 0 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				margin-left: -20px;
				padding: 0;

				@media (--sm-lte) {
					margin: 0 0 20px;
				}

				a {
					color: currentColor;
					display: flex;
					flex-direction: column;
					height: 100%;
					text-decoration: none;
					width: 100%;

					&:hover {
						opacity: .6;
					}
				}

			li {
				list-style:  none;
				margin: 0 0 20px 20px;
				border: 8px solid $gray-color06;
				position: relative;
				width: calc(100% / 3 - 20px);

				@media (--sm-lte) {
					width: 100%;
					margin: 0 0 20px;
				}

				&::before {
					border-bottom: 30px solid transparent;
					border-left: 30px solid $green-color06;
					content: "";
					height: 30px;
					left: -8px;
					position: absolute;
					top: -8px;
					width: 30px;
					z-index: 1000;
				}

				h3 {
					background: none;
					color: $secondary-color;
					font-size: calc(18 / 16 * 1em);
					font-weight: bold;
					margin: 30px;
					position: relative;
					text-align: center;

					&::after {
						position: absolute;
						bottom: -15px;
						left: 0;
						z-index: 2;
						content: "";
						width: calc(100% * .2);
						height: 1px;
						right: 0;
						margin: 0px auto;
						background-color: $secondary-color;
					}
				}

				p {
					margin: 30px;
					text-align: center;

					@media (--sm-lte) {
						margin: 20px;
					}

					&.btn {
						margin-top: auto;
						span {
							background: $secondary-color;
							border-radius: 30px;
							color: $lightest-color;
							display: inline-block;
							font-size: calc(14 / 16 * 1em);
					    min-width: 160px;
							padding: 8px 20px;
							position: relative;
					    text-decoration: none;

							&::after {
								border: 2px solid currentColor;
								border-width: 2px 2px 0 0;
								content: "";
								display: block;
								height: 8px;
								margin-top: -4px;
								position: absolute;
								right: 1.3em;
								top: 50%;
								transform: rotate(45deg);
								width: 8px;
							}
						}
					}
				}

					img {
						height: auto;
						max-width: 100%;
						width: auto;
						z-index: 100;
					}
				}
			}
		}

		.extra-img-index {

			@media (--sm-lte) {
			margin: 0 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				margin-left: -20px;
				padding: 0;

				@media (--sm-lte) {
					margin: 0 0 20px;
				}

				a {
					color: currentColor;
					display: flex;
					flex-direction: column;
					height: 100%;
					text-decoration: none;
					width: 100%;

					&:hover {
						opacity: .6;
					}
				}

			li {
				list-style:  none;
				margin: 0 0 20px 20px;
				border: 8px solid $gray-color06;
				position: relative;
				width: calc(100% / 3 - 20px);

				@media (--sm-lte) {
					width: 100%;
					margin: 0 0 20px;
				}

				&::before {
					border-bottom: 30px solid transparent;
					border-left: 30px solid $green-color06;
					content: "";
					height: 30px;
					left: -8px;
					position: absolute;
					top: -8px;
					width: 30px;
					z-index: 1000;
				}

				h3 {
					background: none;
					color: $secondary-color;
					font-size: calc(18 / 16 * 1em);
					font-weight: bold;
					margin: 20px 30px 30px;
					position: relative;
					text-align: center;

					&::after {
						position: absolute;
						bottom: -15px;
						left: 0;
						z-index: 2;
						content: "";
						width: calc(100% * .2);
						height: 1px;
						right: 0;
						margin: 0px auto;
						background-color: $secondary-color;
					}
				}

				p {
					margin: 30px;
					text-align: center;

					@media (--sm-lte) {
						margin: 20px;
					}

					&.btn {
						margin-top: auto;
						span {
							background: $secondary-color;
							border-radius: 30px;
							color: $lightest-color;
							display: inline-block;
							font-size: calc(14 / 16 * 1em);
					    min-width: 160px;
							padding: 8px 20px;
							position: relative;
					    text-decoration: none;

							&::after {
								border: 2px solid currentColor;
								border-width: 2px 2px 0 0;
								content: "";
								display: block;
								height: 8px;
								margin-top: -4px;
								position: absolute;
								right: 1.3em;
								top: 50%;
								transform: rotate(45deg);
								width: 8px;
							}
						}
					}
				}

					img {
						height: auto;
						max-width: 100%;
						width: auto;
						z-index: 100;
						flex-shrink: 0;
					}
				}
			}
		}
	}

	&__blog-title {
		margin-bottom: 15px;
		font-weight: normal;
		font-size: 2.4rem;
		line-height: 1.5;

		@media (--sm-lte) {
			font-size: 2.2rem;
		}

		&::after {
			display: block;
			content: "";
			width: 100%;
			height: 4px;
			margin-top: 15px;
			background: $grad-color01;

			@media (--sm-lte) {
				height: 3px;
				margin-top: 10px;
			}
		}
	}

	&__blog-date {
		margin: 0 40px 25px;
		text-align: right;
		color: $green-color11;
		font-family: $font-family-list;
		font-size: 2.2rem;
		line-height: 1.2;

		@media (--sm-lte) {
			margin: 0 10px 30px;
			font-size: 2rem;
		}

		a {
			display: inline-block;
			margin-left: 15px;
			padding: 3px 25px 2px;
			line-height: 1.4;
			background-color: $green-color14;
			color: $lightest-color;
			text-align: center;
			text-decoration: none;
			font-size: 1.2rem;
			vertical-align: middle;
			font-family: $font-family-primary;
		}
	}

	&__sns-block {
		display: flex;
		justify-content: center;
		width: 880px;
		padding: 20px 0;
		background-color: $gray-color12;
		margin: 0 auto;
		line-height: 1;

		@media (--sm-lte) {
			width: 100%;
			min-width: 300px;
			padding: 15px 10px;
		}

		&-item {
			margin-right: 10px;
			display: flex;

			.fb-share-button {
				> span {
					vertical-align: top !important;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
