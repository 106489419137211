.c-footer {
	background-color: $lightest-color;
	color: $darkest-color;
	padding: 40px 5%;

	@media (--sm-lte) {
		padding: 1.5em 5%;
	}

	&__body {
		@mixin flexible-width-body;
		margin: 0 auto;
	}

	&__links {
		ul {
			display: flex;
			justify-content: center;
			margin: 0 0 20px;
			padding: 0;
		}

		li {
			display: block;
			margin: 0 1em;
			padding: 0;
		}

		a {
			display: block;
			text-decoration: none;
			padding: 15px;
		}
	}

	&__copyright {
		display: block;
		text-align: center;
		font-family: $font-family-list;

		&-font {
			font-family: Verdana, "Droid Sans";
		}
	}

	&__banner {
		background :url("../img/home/bg-banner.png") no-repeat 0 0;
		background-size: cover;
		padding: 70px 0 60px;

		@media (--sm-lte) {
			padding: 50px 10px 40px;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: 0;
			display: flex;
			justify-content: center;
		}

		&-block {
			width: 1000px;
			margin: 0 auto;
			overflow: hidden;
			position: relative;

			@media (--sm-lte) {
				width: 300px;
			}
		}

		&-slide {
			width: 920px;
			height: 60px;
			margin: 0 auto;
			overflow: hidden;

			@media (--sm-lte) {
				width: 230px;
				height: auto;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			li {
				margin: 0 10px;

				a {
					&:hover {
						opacity: .6;
					}
				}

				/*img {
					@media (--sm-lte) {
						width: 100%;
						height: auto;
					}
				}*/
			}
		}

		&-btn {
			button {
				background-color: transparent;
				border: none;
				cursor: pointer;
				outline: none;
				padding: 0;
				appearance: none;
			}

			.prev,
			.next {
				position: absolute;
				top: 14px;
				z-index: 100;

				@media (--sm-lte) {
					width: 25px;
					height: 25px;
					top: calc(50% - 15px);
				}

				img {
					@media (--sm-lte) {
						width: 100%;
					}
				}
			}

			.prev {
				left: 0;
			}

			.next {
				right: 0;
			}
		}

		&-noslide {
			width: 920px;
			height: 60px;
			margin: 0 auto;
			overflow: hidden;

			@media (--sm-lte) {
				width: 230px;
				height: auto;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				font-size: 0;
			}

			li {
				margin: 0 10px;
				display: inline-block;

				a {
					display: inline-block;

					&:hover {
						opacity: .6;
					}

					img {
						vertical-align: bottom;
					}
				}
			}

			&-btn {
				display: none;

				@media (--sm-lte) {
					display: block;
				}

				button {
					@media (--sm-lte) {
						background-color: transparent;
						border: none;
						cursor: pointer;
						outline: none;
						padding: 0;
						appearance: none;
					}
				}

				.prev,
				.next {
					@media (--sm-lte) {
						position: absolute;
						z-index: 100;
						width: 25px;
						height: 25px;
						top: calc(50% - 15px);
					}

					img {
						@media (--sm-lte) {
							width: 100%;
						}
					}
				}

				.prev {
					@media (--sm-lte) {
						left: 0;
					}
				}

				.next {
					@media (--sm-lte) {
						right: 0;
					}
				}
			}
		}
	}
}
