@media print {
	body {
			width: 1280px;
			transform: scale(0.8);
			transform-origin: 0 0;
	}

	body.c-page-sub {
		.c-header__info,
		.c-nav-global,
		.c-page-sub__content-title,
		.c-page-sub__sns-block,
		.c-nav-local,
		.c-parts-button__back,
		.c-page-sub__c-nav-sitemap,
		footer {
			display: none;
		}

		.c-header {
			margin-bottom: 50px
		}
	}
}
