.c-list-categorytag {
	background-image: linear-gradient(-45deg, $gray-color02 25%, $gray-color08 25%, $gray-color08 50%, $gray-color02 50%, $gray-color02 75%, $gray-color08 75%, $gray-color08);
	background-size: 7px 7px;
	display: flex;
	font-size: calc(13 / 16 * 1em);
	margin-bottom: 50px;
	padding: 20px;

	@media (--sm-lte) {
		margin-bottom: 40px;
		padding: 15px;
	}

	&-heading {
		display: inline-flex;
		flex:0 0 92px;
		margin: 0;

		@media (--sm-lte) {
			flex:0 0 84px;
		}
	}

	ul {
		align-items: stretch;
		display: inline-flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0 0 -10px;
		padding: 0;

		li {
			padding: 0;
			margin: 0 20px 10px 0;

			@media (--sm-lte) {
				margin: 0 0 14px 14px;
			}

			a {
				background: $yellow-color02;
				color: $lightest-color;
				display: block;
				line-height: 1.4;
				text-align: center;
				text-decoration: none;
				padding: 3px 10px 2px;

				@media (--sm-lte) {
					font-size: 1.1rem;
					padding: 2px 8px 1px;
					letter-spacing: 0;
				}

				&:hover {
					opacity: .6;
				}
			}
		}
	}
}
.c-list-dblog {
	margin-bottom: 50px;

	@media (--sm-lte) {
		margin-bottom: 30px;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		padding: 0;

		@media (--sm-lte) {
			margin: 0;
		}
	}

	li {
		width: calc((100% / 3) - 20px);
		margin: 0 0 20px 20px;
		list-style:  none;
		border: 5px solid $green-color15;
		position: relative;

		@media (--sm-lte) {
			width: 100%;
			margin: 0 0 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: $darkest-color;
			display: block;
			height: 100%;
			padding: 20px 20px 40px;
			text-decoration: none;
			width: 100%;

			&:hover {
				opacity: .6;
			}

			&::after {
			}

			p {
				margin-top: 0;
			}
		}

		figure {
			height: 154px;
			margin: 0 0 10px;
			padding: 0;
			text-align: center;
			overflow: hidden;
			position: relative;

			@media (--sm-lte) {
				height: auto;
			}

			img {
				max-width: 100%;
				height: auto;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);

				@media (--sm-lte) {
					position: relative;
					top: 0;
					left: 0;
					transform: translateY(0) translateX(0);
				}
			}
		}

		&.no-data {
			border: none;
		}
	}

	&__sub {
		display: table;
		width: 100%;
		margin-bottom: 10px;
		padding-bottom: 5px;
		border-bottom: 1px solid $yellow-color02;

		&-date {
			display: table-cell;
			color: $green-color11;
			font-family: $font-family-list;
			font-size: 20px;
			vertical-align: middle;

			a {
				 &:hover {
					opacity: .7;
				 }
			}
		}

		&-new {
			display: table-cell;
			width: 20%;
			padding-left: 15px;
			vertical-align: middle;
			text-align: right;

			span {
				display: inline-block;
				padding: 2px 15px 1px;
				border: 1px solid $yellow-color03;
				font-size: 1.3rem;
				line-height: 1.4;
				border-radius: 20px;
				color: $yellow-color03;
				font-family: $font-family-list;
				word-break: normal;
			}
		}
	}

	&__category {
		span {
			padding: 3px 10px 2px;
			line-height: 1.4;
			font-size: 1.2rem;
			background-color: $yellow-color02;
			color: $lightest-color;
		}
	}

	&__title {
		margin-bottom: 0;
		font-size: 1.8rem;
	}

	.post-body {
		color: $yellow-color02;
		font-weight: bold;
		font-size: 1.5rem;
	}

	&__more {
		margin-bottom: 0;
		font-size: 1.4rem;
		position: absolute;
		bottom:20px;
		right: 20px;


		span {
			display: inline-block;
			position: relative;

			&::before {
				border: 1px solid $yellow-color02;
				border-width: 1px 1px 0 0;
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				margin-top: -5px;
				position: absolute;
				left: -20px;
				top: 50%;
				transform: rotate(45deg);
			}
		}
	}
}
