// メインコンテンツ（content-mainコンポーネント）の内容はCMSなどで管理されるHTMLを含んだり、
// ページ独自のスタイルを扱うことが多いので例外的に専用のルールを設ける。
//
// メインコンテンツのエレメントは基本的に自由である。
// ただし、クラス名は`c-`で開始してはいけない。もちろん 【`c-` + `コンポーネント名` + `__` + `エレメント名`】 という規則でクラス名をつけない。
// さらにそのルールに従って、メインコンテンツの中にコンポーネントを内包してはいけない。
//
// 🍔 マークは BurgerEditor専用の意味で、利用しない場合は削除する
//
// stylelint-disable selector-class-pattern
//
.bge-contents, // 🍔
.c-content-main {

	// stylelint-disable selector-nested-pattern
	.pc_only {
		@media only screen and (--sm-lte) {
			display: none;
		}
	}

	.sp_only {
		display: none;

		@media only screen and (--sm-lte) {
			display: block;
		}
	}
	// stylelint-enable selector-nested-pattern
}

// stylelint-enable selector-class-pattern
