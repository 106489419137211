// stylelint-disable selector-class-pattern, selector-nested-pattern
//
// メインコンテンツ（content-mainコンポーネント）の内容はCMSなどで管理されるHTMLを含んだり、
// ページ独自のスタイルを扱うことが多いので例外的に専用のルールを設ける。
//
// メインコンテンツのエレメントは基本的に自由である。
// ただし、クラス名は`c-`で開始してはいけない。もちろん 【`c-` + `コンポーネント名` + `__` + `エレメント名`】 という規則でクラス名をつけない。
// さらにそのルールに従って、メインコンテンツの中にコンポーネントを内包してはいけない。
//
// 🍔 マークは BurgerEditor専用の意味で、利用しない場合は削除する
//
.bge-contents, // 🍔
.c-content-main {
	margin-left: 15px;
	margin-right: 15px;

	@media (--sm-lte) {
		margin-left: 10px;
		margin-right: 10px;
	}

	// 🍔 全ブロック基本の余白
	$bgb-default-margin-bottom: 3em;
	// 🍔 背景色・枠線がある場合のパディング
	$bgb-opt-padding: .8em;

	//
	// 基本スタイル
	//
	font-size: 1.6rem;
	line-height: 1.9;

	//@media (--xs) {
		//font-size: calc(16 / 320 * 100vw); // 幅320pxのデバイスで16pt
	//}
	//
	// エレメント
	//
	// ☝ 以降のフォントサイズの指定単位は em を推奨
	//
	a {
		color: currentColor;
		word-break: break-all;
	}

	h2 {
		font-size: calc(32 / 16 * 1em);
		color: $darkest-color02;
		line-height: 1.3;
		margin: 1em 0 40px;
		padding: 0 0 15px;
		position: relative;
		font-weight: normal;
		border-bottom: 4px solid $gray-color13;

		@media (--sm-lte) {
			font-size: calc(24 / 16 * 1em);
			line-height: 1.4;
			margin: 0 0 30px;
		}

		&:after {
			display: block;
			position: absolute;
			bottom: -4px;
			left: 0;
			z-index: 2;
			content: "";
			width: 16%;
			height: 4px;
			background-color: $green-color24;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	// 🍔
	.bge-title-h2,
	.bge-title-h3 {
		margin-left: -15px;
		margin-right: -15px;

		@media (--sm-lte) {
			margin-left: -10px;
			margin-right: -10px;
		}
	}

	h3 {
		font-size: calc(26 / 16 * 1em);
		font-weight: normal;
		line-height: 1.4;
		margin: 1em 0 35px;
		padding: 14px 20px 10px;
		color: $lightest-color;
		background: url(/img/common/bg_repeat_01.png) left top repeat;
		border-radius: 5px;

			@media (--sm-lte) {
				font-size: calc(22 / 16 * 1em);
				margin: 0 0 25px;
			}

		&:first-child {
			margin-top: 0;
		}
	}

	h4 {
		font-size: calc(24 / 16 * 1em);
		font-weight: normal;
		line-height: 1.5;
		margin: 1em 0 30px;
		padding: 12px 15px;
		background-color: $green-color15;
		position: relative;
		border-left: 5px solid $green-color24;

		@media (--sm-lte) {
			font-size: calc(20 / 16 * 1em);
			line-height: 1.6;
			margin: 0 0 30px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h5 {
		font-size: calc(20 / 16 * 1em);
		font-weight: normal;
		color: $darkest-color02;
		line-height: 2;
		margin: 1em 0 20px;
		padding: 0 0 0 30px;
		background: url("/img/common/icon_polygon.png") left 8px no-repeat;
		background-size: 18px 20px;

		@media (--sm-lte) {
			font-size: calc(18 / 16 * 1em);
			background-position: left 6px;
			margin: 0 0 20px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h6 {
		font-size: calc(20 / 16 * 1em);
		margin: 1em 0 20px;
		color: $green-color25;
		font-weight: normal;

		@media (--sm-lte) {
			margin: 0 0 20px;
			font-size: calc(16 / 16 * 1em);
		}

		&:first-child {
			margin-top: 0;
		}
	}

	p {
		@media (--sm-lte) {
		font-size: calc(14 / 16 * 1em);
		}

		a[target="_blank"] {
			background: url(../img/common/icon_external_02.png) 100% 0 no-repeat;
			padding-bottom: 5px;
			padding-right: 20px;
			margin-right: 5px;
		}
	}

	ul {
		padding-left: 10px;

		@media (--sm-lte) {
			font-size: 1.3rem;
		}

		> li {
			list-style: none;
			text-indent: -1em;
			padding-left: 1em;
			word-break: break-all;

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin: -.4rem 15px 0 -11px;
				width: 11px;
				height: 11px;
				background-image: url("/img/common/icon_list.png");
				background-repeat: no-repeat;
				background-position: 0 50%;
				background-size: 11px;
			}
			> ul {
				margin: 0 0 0 20px;

				@media (--sm-lte) {
					margin: 0;
				}

				 li{
					 text-indent: -1em;
					 padding-left: 1em;

					 &::before {
						content: "";
						display: inline-block;
						vertical-align: middle;
						margin: -.4rem 15px 0 -8px;
						width: 8px;
						height: 8px;
						background-image: url("/img/common/icon_list_small.png");
						background-repeat: no-repeat;
						background-position: 0 50%;
						background-size: 8px;
					}
				 }
			}

			a{
				height: auto;

				&[target=_blank] {
					background: url("../img/common/icon_external_02.png") 100% 4px no-repeat;
					padding-right: 24px;

					@media (--sm-lte) {
						background: url("../img/common/icon_external_02.png") 100% 0px no-repeat;
						padding-bottom: 5px;
					}
				}

				&[href*=".pdf"] {
					background: url("../img/common/icon_pdf.png") 100% 0 no-repeat;
					padding-right: 24px;

					@media (--sm-lte) {
						background-size: 14px 18px;
					}
				}

				&[href*=".doc"],
				&[href*=".docx"],
				&[href*=".docm"]{
					background: url("../img/common/icon_word.png") 100% 0 no-repeat;
					padding-right: 24px;

					@media (--sm-lte) {
						background-size: 14px 18px;
					}
				}

				&[href*=".xls"],
				&[href*=".xlsx"],
				&[href*=".xlsm"]{
					background: url("../img/common/icon_excel.png") 100% 0 no-repeat;
					padding-right: 24px;

					@media (--sm-lte) {
						background-size: 14px 18px;
					}
				}
			}
		}

		ul,
		ol {
			// Lv.2以降
			margin-left: $indent-sub-list;
		}
	}

	ol {
		counter-reset: content-main-ol-default;
		padding-left: 12px;

		@media (--sm-lte) {
			font-size: 1.3rem;
		}

		> li {
			list-style: none;
			text-indent: -1em;
			padding-left: 1em;

			&::before {
				content: counter(content-main-ol-default, decimal-leading-zero) ". ";
				counter-increment: content-main-ol-default;
				color: $secondary-color;
				font-family: $font-family-list;
				font-size: calc(20 / 16 * 1em);
				margin: 0 5px 0 0;
		    width: 10px;
		    display: inline-block;
			}

			> ol {
				margin: 0;
				padding-left: 15px;

				@media (--sm-lte) {
					margin: 0;
				}

				> li{
					text-indent: -1em;
					padding-left: 1em;

					&::before {
						content: counter(content-main-ol-default, decimal-leading-zero) ". ";
						counter-increment: content-main-ol-default;
						color: $primary-color;
						font-family: $font-family-list;
					}
				}
			}
		}

		ul,
		ol {
			// Lv.2以降
		}

		&.mt0 {
			margin-top: 0;
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;
		margin-bottom: 30px;

		@media (--sm-lte) {
			font-size: calc(13 / 16 * 1em);
			display: block;
		}
	}

	caption {
		font-weight: bold;
		text-align: left;

		@media (--sm-lte) {
			display: block;
		}
	}

	thead {
		@media (--sm-lte) {
			display: none;
		}
	}

	tfoot {
		@media (--sm-lte) {
			display: none;
		}
	}

	tbody {
		@media (--sm-lte) {
			display: block;
		}
	}

	tr {
		@media (--sm-lte) {
			display: block;
		}
	}

	th,
	td {
		padding: 1.2em;
		color: $font-primary-color;

		@media (--sm-lte) {
			display: block;
			margin-top: -1px;
			width: 100% !important; // stylelint-disable-line declaration-no-important
		}
	}

	th {
		border-top: 1px solid $secondary-color;
		border-bottom: 1px solid $secondary-color;
		background: $green-color02;
		text-align: left;
		color: $green-color03;

		@media (--sm-lte) {
			padding: 10px 4% 10px 6%;
		}
	}

	td {
		border-top: 1px solid $gray-color05;
		border-bottom: 1px solid $gray-color05;

		@media (--sm-lte) {
			padding: 10px 4% 20px 6%;
		}
	}

	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	blockquote {
		background: $gray-color01;
		margin: 0 0 0 $indent-sub-list;
		padding: 1em 2em;
		position: relative;

		&::before {
			content: "“";
			display: block;
			font-size: calc(40 / 16 * 1em);
			left: 0;
			line-height: 0;
			margin-top: .5em;
			opacity: .2;
			pointer-events: none;
			position: absolute;
			top: 0;
			user-select: none;
		}

		&::after {
			bottom: 0;
			content: "”";
			display: block;
			font-size: calc(40 / 16 * 1em);
			line-height: 0;
			opacity: .2;
			pointer-events: none;
			position: absolute;
			right: 0;
			user-select: none;
		}
	}

	figure {
		margin: 0;
	}

	figcaption {
		background: none;
		font-size: calc(14 / 16 * 1em);
		font-weight: normal;
		line-height: 1.6;
		padding: .8em 1.2em;
		text-align: left;
		word-break: break-all;
	}

	hr {
		color: $gray-color03;
		background: $gray-color03;
		border-style: none;
		height: 2px;
		margin: 50px auto;
		width: 100%;

		&:first-child {
			margin-top: 0;
		}
	}

	input {
		font-size: 1.6rem;
		max-width: 100%;

		&[type="text"],
		&[type="email"] {
			border: 1px solid $border-primary-color;
			padding: .3em;

			&:focus {
				box-shadow:
					0 0 10px 0 $btn-glow-color,
					0 0 10px 0 $lightest-color;
				outline: none;
			}
		}
	}

	textarea {
		border: 1px solid $border-primary-color;
		font-size: 1.6rem;
		padding: .3em;
		width: 100%;

		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 $lightest-color;
			outline: none;
		}
	}

	// フォーム入力
	.form_input_list,
	.form_thanks {
		margin-left: -15px;
		margin-right: -15px;

		@media (--sm-lte) {
			margin-left: -10px;
			margin-right: -10px;
		}

		.flow_img {
			margin-bottom: 40px;
			text-align: center;

			@media (--sm-lte) {
				width: 100%
			}

			img {
				@media (--sm-lte) {
					width: 100%;
				}
			}
		}
	}

	table.form_input {

		th {
			width: calc(30 / 100 * 100%);
			background: none;
			border-bottom: 1px solid $green-color08;
			border-top: none;
			color: $darkest-color;
			border-right: 20px solid $lightest-color;

			@media (--sm-lte) {
				width: 100%;
				border-right: none;
				padding: 10px;
			}

			.must_icon {
				display: inline-block;
				margin-left: 10px;
				padding: 3px 12px;
				color: $lightest-color;
				background-color: $secondary-color;
				border-radius: 20px;
				font-size: 1.2rem;
				line-height: 1.4;
				vertical-align: middle;
				font-weight: normal;
			}
		}

		td {
			border-bottom: 1px solid $green-color08;
			border-top: none;

			@media (--sm-lte) {
				padding: 15px 10px 20px;
				border-bottom: none;
			}

			.form_l {
				width: 100%;
			}

			input {
				padding: 10px;
			}

			input[type=radio] {
				display: none;
			}

			input[type=radio] + label{
				padding-left: 24px;
				position:relative;
				margin-right: 15px;
			}

			input[type=radio] + label::before{
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 18px;
				height: 18px;
				border: 2px solid $green-color08;
				border-radius: 50%;
			}

			input[type=radio]:checked + label{
				color: #009a9a;
			}

			input[type=radio]:checked + label::after{
				content: "";
				display: block;
				position: absolute;
				top: 4px;
				left: 4px;
				width: 10px;
				height: 10px;
				background: #009a9a;
				border-radius: 50%;
			}

			textarea {
				resize: none;
			}

			select {
				padding: 10px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border-radius: 0;
				border: 1px solid $border-primary-color;
				margin: 0;
				padding: 10px 40px 10px 10px;
				vertical-align: middle;
				position: relative;
				font-size: 1em;
				background: url("/img/common/icon-selectbox.png") right center no-repeat;

				@media (--sm-lte) {
					width: 100%;
				}

				&::-ms-expand {
					display: none;
				  }
			}

			p {
				font-size: 1.2rem;
			}
		}
	}

	.form_thanks {

		h2 {
			&::after {
				// left: calc((50 / 100 * 100%) - 35px);
				// right: calc((50 / 100 * 100%) - 35px);
			}
		}
	}

	//脳疲労度チェック
	.check_input {
		margin: 0 auto;
		width: 880px;

		@media (--sm-lte) {
			width: 100%;
		}

		p {
			@media (--sm-lte) {
				font-size: 100%;
				line-height: 1.7;
			}
		}

		&-info {
			margin-bottom: 60px;

			@media (--sm-lte) {
				font-size: 13px;
				margin-bottom: 30px;
			}

			tr {
				&:last-child {
					margin-bottom: 40px;
				}
			}

			th {
				background: none;
				border-bottom: 1px solid $green-color08;
				border-right: 20px solid $lightest-color;
				border-top: none;
				color: $font-primary-color;
				font-weight: bold;
				padding: 20px 30px;
				width: calc(100% * .27);
				vertical-align: middle;

				@media (--sm-lte) {
					font-size: calc(20 / 16 * 1em);
					margin-bottom: 15px;
					padding:0 0  5.5px;
					line-height: 1.7;
				}
			}

			td {
				border-bottom: 1px solid $green-color08;
				border-top: none;
				padding: 20px 30px;

				@media (--sm-lte) {
					border-bottom: none;
					padding: 0;
					margin-bottom: 20px;
				}

				> small {
					@media (--sm-lte) {
						transform: translateY(-5px);
						display: block;
					}
				}
			}
		}

		ul {
			margin: 0 0 60px;

			@media (--sm-lte) {
				margin: 0 0 40px;
				padding-left: 0;
			}

			li {
				margin-bottom: 20px;
				padding-left: 0;
				text-indent: 0;

				@media (--sm-lte) {
					margin-bottom: 10px;
				}

				&::before {
					content: none;
				}
			}

			　&.situation {
				border-top:  1px solid $green-color08;

				@media (--sm-lte) {
					border-top: none;
				}

				li {
					border-bottom: 1px solid $green-color08;
					margin-bottom: 10px;

					@media (--sm-lte) {
						border-bottom: none;
					}

					p {
						margin: 15px 0;

						@media (--sm-lte) {
							margin: 15px 0 25px;
						}
					}
				}
			}
		}

		.q-ttl {
			color: $green-color25;
			font-size: calc(20 / 16 * 1em);
			margin: 20px 0;
			padding: 4px 0 0 52px;
			position: relative;

			@media (--sm-lte) {
				border-bottom: 1px solid $green-color08;
				padding: 0 0 5px 52px;
				margin: 10px 0 !important;
			}

			span {
				content:"";
				left: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				@media (--sm-lte) {
					top: 2px;
					transform: translateY(0);
					margin: 0;
				}
			}
		}

		.a-txt {
			font-size: calc(16 / 16 * 1em);
			margin: 20px 0;
			padding-left: 52px;

			@media (--sm-lte) {
				font-size: 13px;
				margin: 15px 0 20px;
				padding: 0;
			}
		}

		.q_icon {
			font-weight: normal;
			display: inline-block;
			padding: 3px 8px;
			color: $lightest-color;
			background-color: $secondary-color;
			border-radius: 20px;
			font-size: 1.2rem;
			line-height: 1.4;
			margin-right: 16px;
			min-width: 42px;
			vertical-align: middle;
			text-align: center;

			&-s {
				display: inline-block;
				padding: 3px 6px;
				color: $lightest-color;
				background-color: $secondary-color;
				border-radius: 20px;
				font-size: 1.2rem;
				line-height: 1.4;
				margin-right: 16px;
				vertical-align: middle;
				font-weight: normal;
				text-align: center;
				margin-top: -4px;
				@media (--sm-lte) {
					min-width: 42px;
					padding: 3px 8px;
					margin-right: 10px;
				}
			}
		}

		.form_l {
			width: 100%;
		}

		input {
			padding: 10px;
		}

		input[type="text"]{
			&.input_s {
				margin-right: 10px;
				width: 100px;

				@media (--sm-lte) {
					margin-bottom: 10px;
					margin-top: 3px;
				}
			}
		}

		input[type=radio] {
			display: none;
		}

		input[type=radio] + label{
			padding-left: 24px;
			position:relative;
			margin-right: 15px;

			@media (--sm-lte) {
				display: inline-block;
				margin-bottom: 10px;
			}
		}

		input[type=radio] + label::before{
			content: "";
			display: block;
			position: absolute;
			top: 2px;
			left: 0;
			width: 18px;
			height: 18px;
			border: 2px solid $green-color08;
			border-radius: 50%;
		}

		input[type=radio]:checked + label{
			color: $darkest-color;
		}

		input[type=radio]:checked + label::after{
			content: "";
			display: block;
			position: absolute;
			top: 6px;
			left: 4px;
			width: 10px;
			height: 10px;
			background: $secondary-color;
			border-radius: 50%;
		}

		textarea {
			resize: none;
		}

		select {
			padding: 10px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border-radius: 0;
			border: 1px solid $border-primary-color;
			margin: 0;
			padding: 10px 40px 10px 10px;
			vertical-align: middle;
			position: relative;
			font-size: 1em;
			background: url("/img/common/icon-selectbox.png") right center no-repeat;

			&.select_m {
				width: 254px;
			}

			@media (--sm-lte) {
				width: 100%;
				margin-bottom: 10px;
				margin-top: 3px;
			}

			&::-ms-expand {
				display: none;
			}
		}

		p.lead {
			margin-bottom: 35px !important;
		}
	}

	.title-h4-check {
		margin-left: -15px;
		margin-right: -15px;
		width: calc(100% + 30px);
	}

	// 脳疲労診断結果
	.check_result {
		.diagnosis {
			text-align: center;
			padding: 0 0 100px;

			@media (--sm-lte) {
				padding: 0 0 50px;
			}

			h4 {
				background-color: $lightest-color;
				border-left: none;
				color: $secondary-color;
				font-size: calc(24 / 16 * 1em);
				margin: 0 auto 25px;
				padding: 0;

				@media (--sm-lte) {
					margin: 0 auto 12px;
				}
			}

			p {
				margin: 0 0 40px;

				@media (--sm-lte) {
					margin: 0 0 15px;
					text-align: left !important;
				}
			}
		}

		.btn-group{
			[data-bgt-button-kind=back] {

				@media (--sm-lte) {
					min-width: 90%;
				}

				&::before {
					border: 2px solid $lightest-color;
					border-width: 0 0 2px 2px;
					content: "";
					display: block;
					height: 10px;
					left: 1.3em;
					margin-top: -5px;
					position: absolute;
					top: 50%;
					transform: rotate(45deg);
					width: 10px;
					z-index: 1;
				}


				&::after {
					content: none;
				}

				.replay {
					line-height: 1.15;
					min-width: 300px;

					@media (--sm-lte) {
						min-width: 90%;
					}

					&::before {
						content: none;
					}
				}
			}
		}
	}

	//ボタン
	.btn-group {
		margin-top: 3em;
		text-align: center;
		display: flex;
		justify-content: center;

		@media (--sm-lte) {
			margin-top: 2em;
			flex-direction: column;
		}

		p {
			width: 360px;
			margin: 0;
			position: relative;

			@media (--sm-lte) {
				width: 90%;
				margin: 0 auto;
			}

			&.narrow {
				width: 300px;

			@media (--sm-lte) {
				width: 90%;
				}
			}
		}

		input,
		button,
		a {
			display: block;
			width: 100%;
			padding: 1.5em 2em;
			appearance: none;
			background: $secondary-color;
			color: $lightest-color;
			border: none;
			text-align: center;
			cursor: pointer;
			border-radius: 50px;
			font-size: 1.6rem;
			position: relative;
			text-decoration: none;

			&:hover {
				opacity: .7;
			}
		}

		a {
			padding: 1em 2em;
		}

		button {
			width: 40%;
			margin: 0 20px;

			@media (--sm-lte) {
				width: 100%;
				margin: 0 0 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		p,
		button {
			&::after {
				border: 2px solid $lightest-color;
				border-width: 2px 2px 0 0;
				content: "";
				display: block;
				height: 10px;
				margin-top: -5px;
				position: absolute;
				right: 1.3em;
				top: 50%;
				transform: rotate(45deg);
				width: 10px;
			}
		}

		.btn-back {
			background: $green-color04;

			&::after {
				right: 0;
				left: 1.3em;
				transform: rotate(-135deg);
			}
		}

		&-back {
		  p {
				width: 360px;
		    margin: 0 auto;
		    position: relative;

				@media (--sm-lte) {
					width: 90%;
				}

		    &::before {
		      background: none;
		      border: 2px solid $lightest-color;
		      border-width: 0 0 2px 2px;
		      content: "";
		      display: block;
		      height: 10px;
		      left: 1.3em;
		      margin-top: -5px;
		      position: absolute;
		      top: 50%;
		      transform: rotate(45deg);
		      width: 10px;
					z-index: 100;
		    }

		    &::after {
		      display: none;
		    }

				a {
					background-color: $green-color04;
					border-radius: 50px;
				}
		  }
		}
	}

	//table
	.bge-type-table {
		td {
			a[target="_blank"] {
				background: url(../img/common/icon_external_02.png) 100% 0 no-repeat;
				padding-bottom: 5px;
				padding-right: 20px;
				margin-right: 5px;
			}
		}
	}

	//
	// 💊 ヘルパークラス
	//
	// 👇 以下 👇
	//
	// 🚫 削除非推奨 🚫
	//
	%grid-frame {
		align-content: flex-start;
		align-items: flex-start;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}

	.grid-frame {
		@extend %grid-frame;
	}

	.grid-frame-flex {
		@extend %grid-frame;
		flex-wrap: nowrap;
	}

	@for $i from 1 to 12 {
		.grid-xs-$(i) { @media (--xs) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-sm-$(i) { @media (--sm) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-md-$(i) { @media (--md) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-lg-$(i) { @media (--lg) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-xl-$(i) { @media (--xl) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
	}

	.grid-xs-d5 { @media (--xs) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-sm-d5 { @media (--sm) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-md-d5 { @media (--md) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-lg-d5 { @media (--lg) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-xl-d5 { @media (--xl) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-xs-flex { @media (--xs) { flex: 0 1 100%; } }
	.grid-sm-flex { @media (--sm) { flex: 0 1 100%; } }
	.grid-md-flex { @media (--md) { flex: 0 1 100%; } }
	.grid-lg-flex { @media (--lg) { flex: 0 1 100%; } }
	.grid-xl-flex { @media (--xl) { flex: 0 1 100%; } }

	// マージンヘルパー
	.mt-zero {
		margin-top: 0;
	}

	.mb-zero {
		margin-bottom: 0;
	}

	.mt-narrow {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-top: $margin-narrow-xs; }
		@media (--sm) { margin-top: $margin-narrow-sm; }
		@media (--md) { margin-top: $margin-narrow-md; }
		@media (--lg) { margin-top: $margin-narrow-lg; }
		@media (--xl) { margin-top: $margin-narrow-xl; }
		// stylelint-enable at-rule-empty-line-before
	}

	.mt-wide {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-top: $margin-wide-xs; }
		@media (--sm) { margin-top: $margin-wide-sm; }
		@media (--md) { margin-top: $margin-wide-md; }
		@media (--lg) { margin-top: $margin-wide-lg; }
		@media (--xl) { margin-top: $margin-wide-xl; }
		// stylelint-enable at-rule-empty-line-before
	}

	.mb-narrow {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-bottom: $margin-narrow-xs; }
		@media (--sm) { margin-bottom: $margin-narrow-sm; }
		@media (--md) { margin-bottom: $margin-narrow-md; }
		@media (--lg) { margin-bottom: $margin-narrow-lg; }
		@media (--xl) { margin-bottom: $margin-narrow-xl; }
		// stylelint-enable at-rule-empty-line-before
	}

	.mb-wide {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-bottom: $margin-wide-xs; }
		@media (--sm) { margin-bottom: $margin-wide-sm; }
		@media (--md) { margin-bottom: $margin-wide-md; }
		@media (--lg) { margin-bottom: $margin-wide-lg; }
		@media (--xl) { margin-bottom: $margin-wide-xl; }
		// stylelint-enable at-rule-empty-line-before
	}
	//
	// 💊 ヘルパークラス
	//
	// 👆 以上 👆
	//

	//
	// 🍔 BurgerEditor用
	//
	// 👇 以下 👇
	//
	// 必要ない場合は削除する 🔪
	//
	[data-bgb] {
		margin-bottom: $bgb-default-margin-bottom;
	}

	// ボタンタイプ
	[data-bgb="button"] [data-bgt="button"],
	[data-bgb="download-file"] [data-bgt="download-file"] {
		@media (--sm-lte) {
			display: block;
		}
	}

	[data-bgb="button3"] {
		[data-bgt="button"] {
			display: block;

			.bgt-btn {
				display: block;
				min-width: 0;
			}
		}
	}

	.bgt-btn {
		appearance: none;
		background: $secondary-color;
		border: none;
		color: $lightest-color;
		display: inline-block;
		font-size: calc(16 / 16 * 1em);
		line-height: 2;
		min-width: 360px;
		padding: 1em 2em;
		position: relative;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		border-radius: 50px;

		@media (--sm-lte) {
			display: block;
			width: 90%;
			min-width: 90%;
			margin-left: auto;
			margin-right: auto;
		}

		&:active,
		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 $lightest-color;
		}

		&:disabled {
			opacity: .6;
		}

		&::after {
			border: 2px solid currentColor;
			border-width: 2px 2px 0 0;
			content: "";
			display: block;
			height: 10px;
			margin-top: -5px;
			position: absolute;
			right: 1.3em;
			top: 50%;
			transform: rotate(45deg);
			width: 10px;
		}
	}

	[data-bgt-button-kind="link"] .bgt-btn {
		//
	}

	[data-bgt-button-kind="em"] .bgt-btn {
		background: $accent-color;
	}

	[data-bgt-button-kind="external"] .bgt-btn {

		&::after {
			width: 14px;
			height: 14px;
			background-image: url("/img/common/icon_external.png");
			background-repeat: no-repeat;
			background-position: 0 50%;
			background-size: 14px;
			margin-top: -7px;
			vertical-align: middle;
			transform: none;
			border: none;

			span {
				&::after {
					content: "";
				}
			}
		}
	}

	[data-bgt-button-kind="back"] .bgt-btn {
		background-color: $green-color04;

		&::before {
			background: none;
			border: 2px solid currentColor;
			border-width: 0 0 2px 2px;
			content: "";
			display: block;
			height: 10px;
			left: 1.3em;
			margin-top: -5px;
			position: absolute;
			top: 50%;
			transform: rotate(45deg);
			width: 10px;
		}

		&::after {
			display: none;
		}
	}

	.bgt-hr {
		margin: 0 auto;
	}

	[data-bgt-hr-kind="dashed"] .bgt-hr {
		height: 0;
		border: none;
		background: none;
		border-bottom: 2px dashed currentColor;
	}

	[data-bgt-hr-kind="bold"] .bgt-hr {
		height: 5px;
	}

	[data-bgt-hr-kind="narrow"] .bgt-hr {
		height: 1px;
	}

	[data-bgt-hr-kind="short"] .bgt-hr {
		width: calc(60 / 100 * 100%);
		height: 1px;
	}

	// ダウンロードファイルタイプ
	[data-bgb="download-file3"] {
		.bgt-download-file__link {
			display: block;
			min-width: 0;
		}
	}

	[data-bgb="download-file3"] .bgt-grid {
		width: 33.3333%;
		float: left;
		padding-left: 6.66667px;
		padding-right: 6.66667px;
	}

	@media screen and (max-width: 768px) {
		[data-bgb="download-file3"] .bgt-grid {
			width: 100%;
			float: none;
			padding-left: 0;
			padding-right: 0;
		}

		[data-bgb="download-file3"] .bgt-grid [data-bgt] > :last-child {
			/* 下マージンはブロック内のタイプの要素に対して指定する (空画像対策) */
			margin-bottom: 20px;
		}

		[data-bgb="download-file3"] .bgt-grid [data-bgt] > :last-child[data-bge-empty="1"] {
			margin-bottom: 0;
		}

		[data-bgb="download-file3"] .bgt-grid:last-child [data-bgt] > :last-child {
			margin-bottom: 0;
		}
	}

	[data-bgb="download-file3"] .bgt-grid--first {
		/* 3列ブロック内 レイアウト用最初のコンテナ */
		padding-left: 0;
		padding-right: 13.33333px;
	}

	@media screen and (max-width: 768px) {
		[data-bgb="download-file3"] .bgt-grid--first {
			padding-right: 0;
		}
	}

	[data-bgb="download-file3"] .bgt-grid--last {
		/* 3列ブロック内 レイアウト用最初のコンテナ */
		padding-left: 13.33333px;
		padding-right: 0;
	}

	@media screen and (max-width: 768px) {
		[data-bgb="download-file3"] .bgt-grid--last {
			padding-left: 0;
		}
	}

	[data-bgb="download-file"],
	[data-bgb="download-file2"],
	[data-bgb="download-file3"] {
		text-align: center;
	}

	.bgb-download-file {
		text-align: center;
	}

	.bgt-download-file__link {
		appearance: none;
		background: $lightest-color;
		border: none;
		color: $secondary-color;
		border: 3px solid $secondary-color;
		border-radius: 50px;
		display: inline-block;
		font-size: calc(16 / 16 * 1em);
		line-height: 2;
		min-width: 360px;
		padding: .8em 2.5em;
		position: relative;
		text-decoration: none;
		vertical-align: middle;

		@media (--sm-lte) {
			display: block;
			width: 90%;
			min-width: 90%;
			margin-left: auto;
			margin-right: auto;
		}

		&:active,
		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 $lightest-color;
		}

		&::after {
			border: 2px solid currentColor;
			border-width: 2px 2px 0 0;
			content: none;
			display: block;
			height: 10px;
			margin-top: -5px;
			position: absolute;
			right: .7em;
			top: 50%;
			transform: rotate(45deg);
			width: 10px;
		}
	}

	.bgt-link__size {
		font-weight: normal;
	}

	.bgt-link__icon {
		display: block;
		margin-top: -10px;
		position: absolute;
		right: 15px;
		top: 50%;
		width: 16px;

		&::before {
			background: url(../img/common/icon_pdf.png) no-repeat;
			content: "";
			height: 20px;
			position: absolute;
			right:0;
			top: 0;
			width: 16px;
		}

		&::after {
			content:none !important;
		}
	}


	// Google Maps
	.bgt-google-maps-link {
		@media (--sm-lte) {
			border: solid 1px $gray-color03;
			border-radius: 3px;
			color: $gray-color04;
			margin-top: 10px;
			padding: 1em;
			text-align: center;
			text-decoration: none;

			span {
				display: inline-block;
				vertical-align: middle;
			}

			&::after {
				@mixin bge-icon;
				content: $fa-external-link;
				font-size: 1em;
				vertical-align: middle;
			}
		}
	}

	// 画像リンクのフォーカス
	.bgt-image {
		:link { // stylelint-disable-line selector-pseudo-class-blacklist
			outline: none; // 🍔 Burger ISSUE 👎
		}
	}

	//
	// ブロックオプション用
	//

	// 背景色
	.bgb-opt--bg-gray,
	.bgb-opt--bg-blue,
	.bgb-opt--bg-pink {
		padding: $bgb-opt-padding;
	}

	// 枠線 - 適用箇所
	.bgb-opt--border-trbl {
		padding: $bgb-opt-padding;
	}

	.bgb-opt--border-td, // クラス名のタイポ // 🍔 Burger ISSUE 👎
	.bgb-opt--border-tb {
		border-left-width: 0 !important; // stylelint-disable-line declaration-no-important
		border-right-width: 0 !important; // stylelint-disable-line declaration-no-important
		padding-bottom: $bgb-opt-padding;
		padding-top: $bgb-opt-padding;
	}

	.bgb-opt--border-lr {
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
	}

	.bgb-opt--border-trl {
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
		padding-top: $bgb-opt-padding;
	}

	.bgb-opt--border-rbl {
		padding-bottom: $bgb-opt-padding;
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
	}

	// 余白
	.bgb-opt--mb-large {
		margin-bottom: calc($bgb-default-margin-bottom * 2) !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--mb-small {
		margin-bottom: calc($bgb-default-margin-bottom / 1.5) !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--mb-none {
		padding-bottom: 0 !important; // stylelint-disable-line declaration-no-important
		margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
	}

	// Wysiwygタイプと画像の余白
	[data-bgt="ckeditor"] {
		&:nth-child(2) {
			margin-top: 1em;
		}
	}

	// galleryタイプ
	[data-bgt="gallery"] {
		$thumb-size: calc(60 / 16 * 1em);

		.bgt-gallery {
			padding-top: calc(9 / 16 * 100%);

			&:hover {
				.bgt-gallery-ctrl {
					opacity: 1;
				}
			}

			&__img {
				img {
					object-fit: cover;
				}
			}

			&__caption {
				color: $lightest-color;
				background: color($darkest-color a(.6));
				padding: .7em 1em;
				text-align: center;
			}

			&-ctrl {
				top: 50%;
				opacity: 0;
				transition: opacity 200ms ease-in;

				&__prev,
				&__next {
					display: block;
					appearance: none;
					margin: -20px 0 0;
					padding: 0;
					background: none;
					border: none;
					transform-origin: center center;

					&:hover {
						opacity: .5;
					}

					&:focus {
						outline: none;
					}

					span {
						display: block;
						overflow: hidden;
						width: 0;
						height: 0;
					}

					&::after {
						content: "";
						display: block;
						width: 40px;
						height: 40px;
						border: solid $lightest-color;
						border-width: 5px 5px 0 0;
					}
				}

				&__prev {
					left: 20px;
					transform: rotate(-135deg);
				}

				&__next {
					right: 20px;
					transform: rotate(45deg);
				}
			}
		}

		.bgt-gallery-marker {
			li {
				&::before {
					display: none;
				}
			}
		}

		[data-gallery-marker="thumbs"] {
			+ .bgt-gallery-marker {
				margin-top: 10px;

				li {
					margin: 0 5px;
					width: $thumb-size;
					height: $thumb-size;
				}
			}
		}

		[data-gallery-marker="dot"] {
			+ .bgt-gallery-marker {
				margin-top: 10px;

				li {
					margin: 0 2px;
					width: 10px;
					height: 10px;
					background-color: $primary-color;
					border-radius: 100%;
					opacity: .6;

					&.current {
						opacity: 1;
					}
				}
			}
		}
	}
	//
	// 👆 以上 👆
	//
	// 🍔 BurgerEditor用
	//

	//Burger内 個別デザイン

	.bc-anchor-link {
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding: 0;
			margin: 0 0 -12px -12px;
			font-weight: bold;

			@media (--sm-lte) {
				display: block;
				margin: 0;
			}
		}

		li {
			width: calc((100% / 5) -12px) ;
			margin: 0 0 12px 12px;
			list-style: none;
			line-height: 1.5;
			border: 1px solid $gray-color05;
			display: flex;
			align-items: center;

			@media (--sm-lte) {
				width: 100%;
				margin: 0 0 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&::before {
				display:  none;
			}

			a {
				display: block;
				width: 100%;
				padding: 18px 30px 18px 15px;
				text-decoration: none;
				color: $gray-color04;
				position: relative;

				&::before {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 15px;
				width: 10px;
				margin: auto;
				content: "";
				vertical-align: middle;
				height: 10px;
				border-top: 2px solid $green-color07;
				border-right: 2px solid $green-color07;
				-webkit-transform: rotate(135deg);
				transform: rotate(135deg);
				}
			}
		}
	}


	//
	// 👇 以下 👇
	//
	// 🐲 baserCMS用
	//
	// 必要ない場合は削除する 🔪
	//
	.bc-heading {
		h2 {
			//
		}
	}

	.bc-time {
		margin-bottom: 5em;
		text-align: right;

		time {
			//
		}
	}

	.bc-cat {
		margin-bottom: 1em;
		text-align: right;

		> div {
			background: $primary-color;
			color: $lightest-color;
			display: inline-block;
			font-size: calc(13 / 16 * 1em);
			padding: .2em .4em;
		}
	}

	.bc-area-cta {
		margin-top: 4em;
		text-align: center;

		a {
			background: $primary-color;

			span {
				&::before {
					@mixin icon;
					color: $gray-color03;
					content: $glyph-email;
					margin-right: .5em;
				}
			}
		}
	}

	.bc-area-ctrl {
		margin-top: 6em;

		@media (--sm-lte) {
			text-align: center;
		}
	}

	.bc-form-table {
		//
	}

	.bc-form-agreement {
		margin-top: 4em;
		text-align: center;
	}

	.bc-form-submit {
		margin-top: 3em;
		text-align: center;
	}

	.bgb-opt--border-bold,
	.bgb-opt--border-tb,
	.bgb-opt--border-tb,
	.bgb-opt--border-lr,
	.bgb-opt--border-rbl,
	.bgb-opt--border-dotted{
		background: $green-color21;
		border-color: $green-color09;
		padding: 2em;

		@media (--sm-lte) {
			padding: 1.6em;
		}
	}

	.bgb-opt--border-trbl {
		padding: 1em;
		border-color: $green-color09;
	}

	.bgb-opt--bg-gray,
	.bgb-opt--bg-blue,
	.bgb-opt--bg-pink {
		padding: 2em;

		@media (--sm-lte) {
			padding: 1em;
		}
	}

	[data-bgb="text-image2"] .bgt-grid--first {
		padding-right: 30px;

		@media (--sm-lte) {
			padding: 0 0 1em;
		}
	}


	[data-bgb="text-float-image1"] .bgt-grid--first {
		margin-left: 30px;

		@media (--sm-lte) {
			margin-left: 0;
		}
	}

	[data-bgb="text-image1"] .bgt-grid--last {
		padding-left: 30px;

		@media (--sm-lte) {
			padding-left: 0;
		}
	}

	[data-bgb="text-float-image2"] .bgt-grid--first {
		margin-right: 30px;

		@media (--sm-lte) {
			margin-right: 0;
		}
	}

	[data-bgb="button2"] .bgt-grid--first, [data-bgb="download-file2"] .bgt-grid--first, [data-bgb="image-link-text2"] .bgt-grid--first, [data-bgb="image-link2"] .bgt-grid--first, [data-bgb="image-text2"] .bgt-grid--first, [data-bgb="image2"] .bgt-grid--first, [data-bgb="trimmed-image-link2"] .bgt-grid--first, [data-bgb="trimmed-image2"] .bgt-grid--first, [data-bgb="wysiwyg2"] .bgt-grid--first
 {
		padding-right: 25px;

		@media (--sm-lte) {
			padding-right: 0;
		}
	}

	[data-bgb="button2"] .bgt-grid--last, [data-bgb="download-file2"] .bgt-grid--last, [data-bgb="image-link-text2"] .bgt-grid--last, [data-bgb="image-link2"] .bgt-grid--last, [data-bgb="image-text2"] .bgt-grid--last, [data-bgb="image2"] .bgt-grid--last, [data-bgb="trimmed-image-link2"] .bgt-grid--last, [data-bgb="trimmed-image2"] .bgt-grid--last, [data-bgb="wysiwyg2"] .bgt-grid--last {
		padding-left: 25px;

		@media (--sm-lte) {
			padding-left: 0;
		}
	}

	a {
		color: $secondary-color;
		position: relative;

		&:hover {
			text-decoration: none;
		}

		&[href^="tel:"] {
			color: currentColor;
			pointer-events: none;
			text-decoration: none;

			@media (--sm-lte) {
				color: $secondary-color;
				pointer-events: auto;
				text-decoration: underline;
			}
		}
	}

	.heading-column3 {
		h3 {
			color: $secondary-color;
			font-size: calc(18 / 16 * 1em);
			margin: 0 auto;
			text-align: center;
		}
	}

	.heading-bd-column3 {
		margin-left: -40px;
		margin-right: -40px;

		@media (--sm-lte) {
			margin-left: 0;
			margin-right: 0;
		}

		.bgt-grid {
			border: 8px solid $gray-color06;
			margin: 0 8px;
			padding-left: 0;
			padding-right: 0;
			position: relative;
			width: calc(100% / 3 - 16px);

			@media (--sm-lte) {
				margin: 0 0 20px;
				width: 100%;
			}

			&::before {
				border-bottom: 30px solid transparent;
				border-left: 30px solid $secondary-color;
				content: "";
				height: 30px;
				left: -8px;
				position: absolute;
				top: -8px;
				width: 30px;
				z-index: 1000;
			}
		}

		.bgt-grid--first {
			padding-right: 0;
		}

		.bgt-grid--last {
			padding-left: 0;
		}

		h3 {
			color: $secondary-color;
			font-size: calc(18 / 16 * 1em);
			font-weight: bold;
			margin: 30px auto;
			position: relative;
			text-align: center;

			&::after {
				position: absolute;
				bottom: -15px;
				left: 0;
				z-index: 2;
				content: "";
				width: calc(100% * .2);
				height: 1px;
				right: 0;
				margin: 0px auto;
				background-color: $secondary-color;
			}
		}

		p {
			margin: 0 30px 30px;
			text-align: center;

			@media (--sm-lte) {
				margin: 0 20px 20px;
			}
		}

		img {
			z-index: 100;
		}
	}

	.post-body {
		> p {
			display: none;
		}
	}

	// リンクつき画像のhover効果
	.bgt-image__link[href],
	.bgt-image-link__link[href] {
		img {
			transition: opacity 300ms;

			&:hover {
				opacity: .7;
			}
		}
	}

	// 画像＋テキストブロックの見出しはみ出し処理
	[data-bgb="text-float-image1"],
	[data-bgb="text-float-image2"] {
		h2,
		h3,
		h4,
		h5,
		h6 {
			overflow: hidden;
		}
	}
}
// stylelint-enable selector-class-pattern, selector-nested-pattern
