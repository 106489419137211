.c-title-page {
	background: url("/img/common/bg-h1_corner.jpg") center top no-repeat;
	background-size: 100% auto;

	@media (--sm-lte) {
		background: url("/img/common/bg-h1_corner-sp.jpg") center top no-repeat;
		background-size: cover;
		display: block;
		padding: 45px 5%;
	}

	h1 {
		display: block;
		color: $darkest-color04;
		width: 100%;
		max-width: 1020px;
		padding:  70px 0 190px 0;
		text-align: center;
		font-size: calc(38 / 16 * 1em);
		font-weight: 500;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		padding-top: 80px;

		@media (--sm-lte) {
			padding: 0;
			font-size: calc(26 / 16 * 1em);
			min-height: initial;
			max-width: initial;
		}
	}
}
