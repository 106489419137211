.c-header {
	font-size: 1.6rem;
	border-bottom: 1px solid $gray-color07;
	padding: 10px 0;

	@media (--sm-lte) {
		padding: 0;
		position: relative;
		overflow: hidden;
	}

	&__body {
		max-width: 1000px;
		min-width: 960px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;

		@media (--sm-lte) {
			display: block;
			max-width: initial;
			min-width: auto;
		}
	}

	&__title {
		flex: 0 1 auto;
		display: flex;

		@media (--sm-lte) {
			width: calc(90 / 100 * 100%);
			padding: 10px 10px 9px;
		}
	}

	&__site-name {
		margin: 0;
		padding-right: 27px;

		> a {
			color: $primary-color;
			display: block;
			text-decoration: none;
			line-height: 1;

			&:hover {
				opacity: .6;
			}

			img {
				vertical-align: top;
				width: 360px;
;
				@media (--sm-lte) {
					height: auto;
					max-width: calc(70 / 100 * 100%);
					width: auto;
				}
			}
		}
	}

	&__description {
		color: $green-color11;
		min-height: 83px;
		display: flex;
		flex: 0 1 auto;
		align-items: flex-end;
		line-height: 1.5;

		@media (--sm-lte) {
			display: none;
		}
	}

	&__info {
		display: flex;
		flex: 0 1 auto;

		@media (--sm-lte) {
			display: none;
		}
	}

	&__links {
		display: flex;
		align-items: center;
		flex: 0 1 auto;
		margin: 0;
		padding: 0;

		@media (--sm-lte) {
			margin: 0 auto 20px;
		}

		> li {
			display: block;
			flex: 0 1 auto;
			padding-left: 15px;
			background-image: url("/img/common/icon-header-list.png");
			background-position: left center;
			background-size: 5px;
			background-repeat: no-repeat;
			margin-left: 20px;

			> a {
				color: $primary-color;
				display:inline-block;
				text-decoration: none;
				font-size: calc(15 / 16 * 1em);
				line-height: 1;
				vertical-align: middle;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__contact {
		display: flex;
		align-items: center;
		margin-left: 20px;

		> a {
			font-family: $font-family-primary02;
			font-size: calc(15 / 16 * 1em);
			font-weight: 700;
			border-radius: 3px;
			color: $lightest-color;
			text-decoration: none;
			flex: 0 1 auto;
			background-color: $primary-color;
			background-image: url("/img/common/icon-mail.png");
			background-position: 35px center;
			background-size: 21px 13px;
			background-repeat: no-repeat;
			padding: 15px 60px 15px 70px;
			position: relative;

			&::after {
				border: 2px solid currentColor;
				border-width: 2px 2px 0 0;
				content: "";
				display: block;
				height: 12px;
				margin-top: -5px;
				position: absolute;
				right: 1.3em;
				top: 50%;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				width: 12px;
			}

			&:hover {
				opacity: .7;
				@media only screen and (--sm-lte) {
					opacity: 1;
				}
			}
		}
	}

	&__menu-trigger {
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		max-width: 85px;
		width: calc( 20 / 100 * 100%);
		height: 100%;
		cursor: pointer;
		background-color: $green-color23;
		padding: 15px 20px;

		@media only screen and (--sm-lte) {
			display: block;
			z-index: 105;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
		}

		div {
			width: 100%;
			height: 75%;
			position: relative;

			span {
				display: block;
				position: absolute;
				width: 100%;
				height: 3px;
				background: $lightest-color;
				transition: 200ms ease-in-out;

				&:nth-child(1) {
					top: 0;
				}

				&:nth-child(2) {
					top: 43%;
				}

				&:nth-child(3) {
					bottom: 0;
				}
			}
		}

		p {
			color: $lightest-color;
			margin-top: 30px;
			margin-bottom: 0;
			text-align: center;
			font-size: calc(14 / 16 * 1em);
			font-weight: bold;
		}
	}

	&__active {
		background-color: $green-color16;

		div {
			top: 50%;
			transform: translateY(-60%);

			span {
				&:nth-child(1) {
					top: 11px;
					transform: rotate(-45deg);
				}

				&:nth-child(2) {
					top: 11px;
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					top: 11px;
					transform: rotate(45deg);
				}
			}
		}
	}
}

// stylelint-disable
// 🐲 baserCMS用管理ツールバー
//
#ToolBar {
	//opacity: 0.2;
	transition: 300ms;__UR
	&:hover {
		opacity: 1;
	}
}
// stylelint-enable
