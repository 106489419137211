.c-card {
	font-size: 1.6rem;
	border: 8px solid $gray-color06;
	height: 100%;
	outline: 8px solid transparent;
	overflow: hidden;
	position: relative;
	transition: outline 300ms;

	&:hover {
		border: 8px solid $secondary-color;
		outline: 2px solid $secondary-color;
	}

	&[data-is-new="true"] {
		&::before {
			background: $primary-color;
			color: $lightest-color;
			// content: "new";
			display: block;
			font-size: calc(14 / 16 * 1em);
			font-weight: bold;
			height: 74px;
			left: -38px;
			padding: 50px 0 0;
			position: absolute;
			text-align: center;
			top: -38px;
			transform: rotate(-45deg);
			width: 74px;
			z-index: 1;
		}
	}

	> a {
		color: currentColor;
		height: 100%;
		text-decoration: none;
	}

	&__img {
		background: $gray-color02;
		flex: 0 1 auto;
		height: 0;
		overflow: hidden;
		padding-top: calc(31 / 50 * 100%);
		position: relative;
		width: 100%;
		margin-bottom: 15px;

		img {
			display: block;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			@supports (object-fit: cover) {
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}

	&__header {
		flex: 0 1 auto;
		padding: 0 22px;
	}

	&__body {
		flex: 0 1 100%;
		font-size: calc(15 / 16 * 1em);
		padding: 10px 22px 30px;
	}

	&__title {
		font-size: calc(18 / 16 * 1em);
		margin: 0 auto;
		padding: 0 0 8px;
		color: $secondary-color;
		text-align: center;
		position: relative;

		&::after {
			content: "";
			display: block;
			margin: 0 auto;
			width: 40px;
			border-bottom: 1px solid $secondary-color;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
		}
	}

	// ブログのみ
	&__meta {
		//
	}

	// ブログのみ
	&__time {
		display: none;
	}

	// ブログのみ
	&__category {
		//
	}

	// ブログのみ
	&__tags {
		ul {
			list-style: none;
			margin: 0 auto;
			padding: 0;
		}
	}

	// ブログのみ
	&__tag-item {
		display: inline-block;
		font-size: calc(12 / 16 * 1em);
		list-style: none;
		margin: 0;
		padding: 0;

		span {
			background: $secondary-color-darker2;
			color: $lightest-color;
			display: block;
			padding: 3px 15px;
		}
	}

	// ブログのみ
	&__author {
		display: none;
	}

	&__description {
		margin: 0;
		padding: 0;
		text-align: center;

		p {
			margin: 0;
			padding: 0;
		}
	}
}
