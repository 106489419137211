.c-page-home {
	&__profile {
		padding-top: 55px;
		background: url("../img/home/bg-profile.png") top left no-repeat $gray-color10;

		@media (--sm-lte) {
			padding-top: 40px;
			background: url("../img/home/bg-profile-sp.png") top left no-repeat $gray-color10;
			background-size: cover;
		}

		&-body {
			display: table;
			width: 770px;
			margin: 0 auto;

			@media (--sm-lte) {
				width: 90%;
			}
		}

		&-img {
			display: table-cell;
			width: 176px;
			line-height: 0;
			vertical-align: bottom;

			@media (--sm-lte) {
					width: calc(35/ 100 * 100%);

					img {
						width: 100%;
					}
			}
		}

		&-text {
			display: table-cell;
			padding-left: 35px;
			font-size: 1.5rem;

			@media (--sm-lte) {
				padding: 0 0 30px 20px;
			}

			&-name {
				&-pc {
					display: block;

					@media (--sm-lte) {
						display: none;
					}
				}

				&-sp {
					display: none;

					@media (--sm-lte) {
						display: block;
						width: 70%;
						margin: 0 auto 20px;
						text-align: center;

						img {
							width: 100%;
						}
					}
				}
			}

			p {
				margin: 20px 0;

				@media (--sm-lte) {
					margin: 0;
				}
			}
		}

		&-btn {
			@media (--sm-lte) {
				margin-top: 20px;
			}

			a {
				display: inline-block;
				color: $lightest-color;
				background: $brown-color01;
				border-radius: 20px;
				font-size: calc(13 / 16 * 1em);
				min-width: 200px;
				padding: 12px 40px;
				position: relative;
				text-align: center;
				text-decoration: none;
				width: auto;

				@media (--sm-lte) {
					min-width: 100%;
					padding: 12px 20px;
				}

				&::after {
					border: 2px solid $lightest-color;
					border-width: 2px 2px 0 0;
					content: "";
					display: block;
					height: 10px;
					margin-top: -5px;
					position: absolute;
					right: 1.3em;
					top: 50%;
					transform: rotate(45deg);
					width: 10px;
				}

				&:hover {
					opacity: .7;
				}
			}
		}
  }

	&__nouhirou {
		background: url("../img/home/bg-nouhirou-leaf.png") 128px -50px no-repeat;
		padding: 72px 0 62px;

		@media (--sm-lte) {
			background-position: -70px -30px;
			background-size: 200px;
			padding: 50px 0 76px;
		}

		&-body {
			margin: 0 auto;
			padding: 0;
			width: 1000px;

			@media (--sm-lte) {
				width: 100%;
				text-align: center;
			}
		}

		&-title {
			font-size: calc(34 / 16 * 1em);
			color: $darkest-color02;
			line-height: 1.8;
			margin: 10px auto 40px;
			padding: 0 0 20px;
			position: relative;
			text-align: center;
			font-weight: normal;

			@media (--sm-lte) {
				padding: 0;
			}

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 2;
				content: "";
				width: 70px;
				height: 2px;
				background-color: $secondary-color;
				margin: 0 auto;
			}
		}

		&-about {
			display: inline-block;
			margin: 0 -15px;
			padding: 0;
			position: relative;

			@media (--sm-lte) {
				margin: 0 30px;
			}

			li {
				float: left;
				list-style: none;
				margin: 0 15px;
				position: relative;
				width: calc(100% / 3 - 30px);

				@media (--sm-lte) {
					float: none;
					max-width: 600px;
					width: 100%;
					margin: 0 0 25px;
				}

				&::before {
					border-bottom: 30px solid transparent;
					border-left: 30px solid $green-color06;
					content: "";
					height: 30px;
					left: 0;
					position: absolute;
					top: 0;
					width: 30px;
					z-index: 100;
				}

				&::after {
					background: url("../img/home/icon-sick.png") top center no-repeat;
					content: "";
					height: 34px;
					left: 0;
					margin: 0 auto;
					position: absolute;
					right: 0;
					top: 30px;
					width: 30px;
				}

				&:first-child {
					&::after {
						background: url("../img/home/icon-about.png") top center no-repeat;
						height: 33px;
						width: 37px;
					}
				}

				&:nth-child(2) {
					&::after {
						background: url("../img/home/icon-check.png") top center no-repeat;
						height: 29px;
						width: 43px;
					}
				}

				@media (--sm-lte) {
					width: 100%;
				}

				a {
					color:currentColor;
					display: block;
					height: auto;
					position: relative;
					text-decoration: none;
					transition: opacity 100ms;
					width: 100%;

					&:hover {
						opacity: .6;
					}

					&.nolink {
						pointer-events:none;
					}
				}

				span {
					color:currentColor;
					display: block;
					height: auto;
					position: relative;
					text-decoration: none;
					transition: opacity 100ms;
					width: 100%;

					&:hover {
						opacity: .6;
					}

					&.nolink {
						pointer-events:none;
					}
				}

				img {
					border: 1px solid $gray-color06;
					border-top: none;
					height: auto;
					width: 100%;
				}
			}
		}

		&-detail {
			font-size: calc(15 / 16 * 1em);
			border: 8px solid $gray-color06;
			border-bottom: none;
			height: 100%;
			outline: 8px solid transparent;
			overflow: hidden;
			padding: 50px 40px 35px;
			position: relative;
			text-align: center;
			transition: outline 300ms;

			@media (--sm-lte) {
				height: auto !important;
				padding: 50px 30px 25px;
			}

			h3 {
				font-size: calc(22 / 16 * 1em);

				@media (--sm-lte) {
					text-align: center;
				}
			}
		}

		&-btn {
			margin: 60px auto 0;
			text-align: center;

			@media (--sm-lte) {
				margin: 10px auto 0;
			}

			a {
				display: inline-block;
				color: $lightest-color;
				background: $green-color17;
				border-radius: 30px;
				font-size: calc(16 / 16 * 1em);
				min-width: 252px;
				padding: 15px 40px;
				position: relative;
				text-align: center;
				text-decoration: none;
				width: auto;

				&::after {
					border: 2px solid $lightest-color;
					border-width: 2px 2px 0 0;
					content: "";
					display: block;
					height: 10px;
					margin-top: -5px;
					position: absolute;
					right: 1.3em;
					top: 50%;
					transform: rotate(45deg);
					width: 10px;
				}


				&:hover {
					opacity: .7;
				}
			}
		}
	}

	&__contents {
		background: url("../img/home/bg-repeat-x-home.png") top left repeat-x;
		margin-bottom: 80px;
		position: relative;

		@media (--sm-lte) {
			margin-bottom: 55px;
		}

		&::before {
			background: url("../img/home/bg-contents-leaf_pc.png") right top no-repeat;
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			top: -100px;
			width: 495px;
			height: 568px;

			@media (--sm-lte) {
				background: url("../img/home/bg-contents-leaf_sp.png") right top no-repeat;
				background-size: 100%;
				width: 60%;
				height: auto;
				margin-bottom: 0;
				top: -45px;
			}
		}

		h2 {
			padding-top: 55px;
			color: $secondary-color;
			font-size: calc(34 / 16 * 1em);
			font-weight: 400;
			position: relative;
			text-align: center;

			@media (--sm-lte) {
				margin: 0 5% 20px;
				padding-top: 40px;
				font-size: 2.4rem;
				z-index: 1;
			}
		}

		&-box {
			border: 8px solid rgba(5, 71, 27, .09);
			margin: 0 auto;
			text-align: center;
			width: 1000px;
			position: relative;
			z-index: 2;

			@media (--sm-lte) {
				width: 100%;
				border-width: 4px;
				margin: 0 15px;
				width: auto;
			}
		}

		&-list {
			background: $lightest-color;
			display: flex;
			list-style: none;
			margin: 0;
			padding: 22px 0;
			width: 100%;

			@media (--sm-lte) {
				flex-wrap: wrap;
				padding: 13px;
			}
		}

		&-item {
			font-size: calc(18 / 16 * 1em);
			font-weight: 700;
			line-height: calc(25 / 18);
			position: relative;
			width: calc(100% / 4);

			@media (--sm-lte) {
				font-size: 1.4rem;
				line-height: 1.5;
				width: calc(100% / 2);
			}

			&:not(:last-child) {
				border-right: 1px solid $secondary-color;

				@media (--sm-lte) {
					border-right: 0;
				}
			}

			a {
				color: $darkest-color03;
				display: block;
				padding: 70px 15px 10px;
				text-decoration: none;
				transition: opacity 100ms;
				width: 100%;

				@media (--sm-lte) {
					padding: 60px 10px 26px;
				}

				&:hover {
					opacity: .6;
				}
			}

			&:nth-child(1) {
				&::before {
					@media (--sm-lte) {
						background: $secondary-color;
						bottom: 7px;
						content: "";
						display: block;
						margin: auto 0;
						position: absolute;
						right: 0;
						top: 0;
						width: 1px;
					}
				}

				&::after {
					@media (--sm-lte) {
						background: $secondary-color;
						bottom: 0;
						content: "";
						display: block;
						height: 1px;
						left: 0;
						margin: 0 auto;
						position: absolute;
						right: 7px;
					}
				}

				a {
					background: url("../img/home/icon-q.png") no-repeat 50% 17px / 38px 38px;

					@media (--sm-lte) {
						background-position: 50% 22px;
						background-size: 25px 25px;
					}
				}
			}

			&:nth-child(2) {
				&::after {
					@media (--sm-lte) {
						background: $secondary-color;
						bottom: 0;
						content: "";
						display: block;
						height: 1px;
						left: 7px;
						margin: 0 auto;
						position: absolute;
						right: 0;
					}
				}

				a {
					background: url("../img/home/icon-try.png") no-repeat 50% 14px / 28px 44px;

					@media (--sm-lte) {
						background-position: 50% 21px;
						background-size: 19px 30px;
					}
				}
			}

			&:nth-child(3) {
				&::before {
					@media (--sm-lte) {
						background: $secondary-color;
						bottom: 0;
						content: "";
						display: block;
						margin: auto 0;
						position: absolute;
						right: 0;
						top: 7px;
						width: 1px;
					}
				}

				a {
					background: url("../img/home/icon-plasmalogen.png") no-repeat 50% 17px / 50px 37px;

					@media (--sm-lte) {
						background-position: 50% 28px;
						background-size: 30px 22px;
					}
				}

				span.nolink {
						background: url("../img/home/icon-plasmalogen-off.png") no-repeat 50% 17px / 50px 37px;
						color: #ccc;
						pointer-events:none;
						display: block;
						padding: 70px 15px 10px;
						text-decoration: none;
						width: 100%;

						@media (--sm-lte) {
							background-position: 50% 28px;
							background-size: 30px 22px;
					}
				}
			}

			&:nth-child(4) {
				a {
					background: url("../img/home/icon-pen.png") no-repeat 50% 18px / 37px 37px;

					@media (--sm-lte) {
						background-position: 50% 29px;
						background-size: 22px 22px;
					}
				}

				span.nolink {
					background: url("../img/home/icon-pen-off.png") no-repeat 50% 18px / 37px 37px;
					color: #ccc;
					pointer-events:none;
					display: block;
					padding: 70px 15px 10px;
					text-decoration: none;
					width: 100%;

					@media (--sm-lte){
						background-position: 50% 29px;
						background-size: 22px 22px;
					}
				}
			}
		}
	}

	&__news {
		background-color: $gray-color10;
		background-image: url("../img/home/bg-news-leaf01.png"), url("../img/home/bg-news-leaf02.png");
		background-position: 0 0, 100% 100%;
		background-repeat: no-repeat;
		padding: 70px 0 80px;

		@media (--sm-lte) {
			background-size: 210px auto, 106px auto;
			padding: 70px 15px 50px;
		}

		.c-page-home__nouhirou-title {
			margin: 10px auto 40px;

			&::after {
				width: 90px;
			}
		}

		ul {
			width: 926px;
			margin: 0 auto;

			@media (--sm-lte) {
				width: 100%;
				padding: 0;
			}

			li {
				background: none;
				border-bottom: 1px solid $green-color22;

				a {
					@media (--sm-lte) {
						padding: 15px 25px 15px 5px;
					}

					p {
						@media (--sm-lte) {
							vertical-align: middle;
						}
					}
				}
			}

			&__sub-category {
				font-size: 1rem;
			}

			&__title {
				@media (--sm-lte) {
					padding:5px 5px 0 90px;
					font-size: 1.2rem;
				}
			}
		}

		.c-page-home__nouhirou-btn {
			margin-top: 50px;

			a {
				&:hover {
					opacity: .7;
				}
			}
		}
	}

	.c-page-home__dblog {
		background: url("../img/home/bg-blog-flower01.png") no-repeat -40px 0, url("../img/home/bg-blog-flower02.png") no-repeat 100% 100%;
		padding: 60px 0 90px;

		@media (--sm-lte) {
			background-size: 170px auto, 70px auto;
			padding:40px 0 70px;
		}

		.c-page-home__nouhirou-title {
			margin: 10px auto 50px;

			&::after {
				width: 90px;
			}
		}

		ul {
			width: 790px;
			margin: 0 auto;
			padding: 0;
			display: flex;
			flex-wrap: wrap;

			@media (--sm-lte) {
				width: 100%;
				padding: 0 44px;
				display: block;
			}

			li {
				width: 250px;
				margin: 0 20px 20px 0;

				@media (--sm-lte) {
					width: 100%;
					margin: 0 0 15px;
				}

				 &:nth-child(3n) {
					margin-right: 0;
				 }

			}
		}

		.c-page-home__nouhirou-btn {
			margin-top: 10px;

			a {
				&:hover {
					opacity: .7;
				}
			}

			@media (--sm-lte) {
				margin-top: 40px;
			}
		}

		.c-list-dblog {
			li {
				figure {
					height: 120px;

					@media (--sm-lte) {
						height: auto;
					}
				}
			}
		}
	}

	&__banner {
		background :url("../img/home/bg-banner.png") no-repeat 0 0;
		background-size: cover;
		padding: 70px 0 60px;

		@media (--sm-lte) {
			padding: 50px 10px 40px;
		}

		&-block {
			width: 1000px;
			margin: 0 auto;
			overflow: hidden;
			position: relative;

			@media (--sm-lte) {
				width: 300px;
			}
		}

		&-slide {
			width: 920px;
			height: 60px;
			margin: 0 auto;
			overflow: hidden;

			@media (--sm-lte) {
				width: 230px;
				height: auto;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			li {
				margin: 0 10px;

				/*img {
					@media (--sm-lte) {
						width: 100%;
						height: auto;
					}
				}*/
			}
		}

		&-btn {
			button {
				background-color: transparent;
				border: none;
				cursor: pointer;
				outline: none;
				padding: 0;
				appearance: none;
			}

			.prev,
			.next {
				position: absolute;
				top: 14px;
				z-index: 100;

				@media (--sm-lte) {
					width: 25px;
					height: 25px;
					top: calc(50% - 15px);
				}

				img {
					@media (--sm-lte) {
						width: 100%;
					}
				}
			}

			.prev {
				left: 0;
			}

			.next {
				right: 0;
			}
		}
	}

}
