.c-nav-sitemap {
	position: relative;
	padding: 30px 0;
	background: $gray-color08;

	@media (--sm-lte) {
		padding: 50px 0 0;
	}

	&__pagetop {
		display: block;
		position: absolute;
		top: -43px;
		right: 10px;
		z-index: 900;
		width: 86px;
		margin: 0;
		transition: all .7s;
		opacity: 1;
		@media (--sm-lte) {
			width: 60px;
			top: -30px;
			right: 5%;

			img {
				width: 100%;
				height: auto;
			}
		}
		&[data-fixed="hide"] {
			position: fixed;
			top: auto;
			bottom: 10px;
			opacity: 0;
			pointer-events: none;
		}
		&[data-fixed="show"] {
			position: fixed;
			top: auto;
			bottom: 10px;
			opacity: 1;
			pointer-events: auto;
		}

		a[href="#top"] {
			display: block;
			padding: 0;
		}
	}

	%list {
		margin: 0;
	}

	%item {
		display: block;
		margin: 0;
		padding: 0;
	}

	a {
		color: $darkest-color;
		display: block;
		text-decoration: none;

		@media (--sm-lte) {
			padding: 20px 25px 20px 20px;
		}

		&:hover {
			opacity: .7;
		}
	}

	&__body {
		background: $gray-color08;
		margin: 0 auto;
		overflow: hidden;
		max-width: 1000px;
		display: flex;
		justify-content: space-between;

		@media (--sm-lte) {
			display: block;
			max-width: 100%;
			border-top: 2px solid $green-color15;
		}
	}

	ul[data-sitemap-level="1"] {
		padding: 20px 0 10px 40px;
		width: calc(1 / 3 * 100%);
		border-left: 1px solid $gray-color09;
		margin: 0;

		&:first-child {
			border-left: none;
			padding-left: 0;
		}

		@media (--sm-lte) {
			width: 100%;
			padding: 0;
		}
	}

	li[data-sitemap-level="1"] {
		@extend %item;
		break-inside: avoid-column;
		display: inline-block; // 💉 for columns item technic
		margin: 0 0 .5em;
		width: 100%; // 💉 for columns item technic
		position: relative;

		@media (--sm-lte) {
			display: block;
			margin: 0;
		}

		a {
			font-size: calc(14 / 16 * 1em);
			font-weight: 700;
			display: inline-block;

			@media (--sm-lte) {
				border-bottom: 2px solid $green-color15;
				margin: 0;
				display: block;

				&::before {
					display: none;
					margin: 0;
				}

				&::after {
					border: 2px solid $secondary-color;
					border-width: 2px 2px 0 0;
					content: "";
					display: block;
					height: 12px;
					margin-top: -5px;
					position: absolute;
					right: 20px;
					top: 50%;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					width: 12px;
				}
			}
		}

		span.nolink {
			color: $gray-color05;
			pointer-events:none;
			font-size: .875em;
			font-weight: 700;
			display: inline-block;

			@media (--sm-lte) {
				border-bottom: 2px solid #e6efeb;
				font-size: calc(14 / 16 * 1em);
				font-weight: 700;
				display: block;
				padding: 20px 25px 20px 20px;
			}

			&:after {
				@media (--sm-lte) {
					border: 2px solid $gray-color05;
					border-width: 2px 2px 0 0;
					content: "";
					display: block;
					height: 12px;
					margin-top: -5px;
					position: absolute;
					right: 20px;
					top: 50%;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					width: 12px;
				}
			}
		}
	}

	ul[data-sitemap-level="2"] {
		@extend %list;
		margin-top: .5em;
		padding: 0;

		@media (--sm-lte) {
			display: none;
		}
	}

	li[data-sitemap-level="2"] {
		@extend %item;
		margin: 0 0 .5em;

		@media (--sm-lte) {
			margin: 0;
		}

		a {
			padding-left: 15px;
			font-weight: 500;

			@media (--sm-lte) {
				border-bottom: 1px solid $secondary-color-darker;
				background: $secondary-color-lighter3;
				padding: 15px $page-viewport-side-margin-xs;
				color: $font-primary-color;
			}

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin: 0 10px 0 -15px;
				width: 6px;
				height: 2px;
				border: 1px solid transparent;
				background-color: $secondary-color;
			}

			&[target="_blank"] {
				background: url(../img/common/icon_external_02.png) 100% 4px no-repeat;
				padding-right: 24px;

				@media (--sm-lte) {
					background: url("../img/common/icon_external_02.png") 100% 0px no-repeat;
					padding-bottom: 5px;
				}
			}
		}

		span.nolink {
			color: $gray-color05;
			pointer-events:none;
			padding-left: 15px;
			font-weight: 500;

			@media (--sm-lte) {
				border-bottom: 1px solid $secondary-color-darker;
				background: $secondary-color-lighter3;
				padding: 15px $page-viewport-side-margin-xs;
				color: $font-primary-color;
			}

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin: 0 10px 0 -15px;
				width: 6px;
				height: 2px;
				border: 1px solid transparent;
				background-color: $gray-color05 !important;
			}

			@media (--sm-lte) {
				&::after {
					border-color: #b4b4b4 !important;
				}
			}
		}
	}

	ul[data-sitemap-level="3"] {
		@extend %list;
		padding: 0 0 0 1em;

		@media (--sm-lte) {
			padding: 0;
		}
	}

	li[data-sitemap-level="3"] {
		@extend %item;

		a {
			@media (--sm-lte) {
				background: $secondary-color-lighter4;
				padding: 10px $page-viewport-side-margin-xs;
				color: $font-primary-color;
			}

			&::before {
				border: 1px solid $secondary-color-lighter2;
				background: $secondary-color-lighter2;

				@media (--sm-lte) {
					display: none;
					margin: 0;
				}
			}
		}
	}

	&__sns {
		ul {
			display: flex;

			li {
				list-style: none;
				a {
					figure {
						margin: 0;
					}
				}
			}
		}
	}
}
