/* stylelint-disable selector-max-universal */
// this is universal selector rules
* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}

	&::selection {
		background-color: $selection-background-color;
	}
}

a {
		&:hover {
			@media (--sm-lte) {
				opacity: 1 !important;
				}
		}
}
