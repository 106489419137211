.c-nav-local {
	margin: 0 auto 80px;
	max-width: 960px;
	width: auto;

	@media (--sm-lte) {
		padding: 0 5%;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0 0 -2px -2px;

		@media (--sm-lte) {
			display: block;
			margin: 0 10px 0;
		}
	}

	li {
		width: calc((100% / 4) -2px) ;
		margin: 0 0 2px 2px;
		list-style: none;
		line-height: 1.5;
		background-color: $green-color26;
		display: flex;
		align-items: center;
		min-height: 85px;

		&:hover {
			opacity: .7;

			@media (--sm-lte) {
				opacity: 1;
			}
		}

		@media (--sm-lte) {
			width: 100%;
			margin: 0 0 2px;
			min-height: auto;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&.current {
			background: $green-color07;
		}

		a {
			display: block;
			width: 100%;
			padding: 18px 25px 18px 15px;
			text-decoration: none;
			color: $lightest-color;
			position: relative;

			&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 15px;
			width: 10px;
			margin: auto;
			content: "";
			vertical-align: middle;
			height: 10px;
			border-top: 2px solid $lightest-color;
			border-right: 2px solid $lightest-color;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			}
		}
	}
}
