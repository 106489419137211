.c-pagination {
	clear: both;
	display: flex;
	font-size: 1.8rem;
	justify-content: center;
	line-height: 4rem;
	margin: 0 0 80px;
	overflow: hidden;
	text-align: center;

	@media (--sm-lte) {
		justify-content: space-between;
		margin: 0 10% 70px;
	}

	a {
		background: none;
		color: currentColor;
		text-decoration: none;
		font-size: calc(16 / 18 * 1em);
	}

	&__prev,
	&__next {
		a {
			border: 1px solid $secondary-color;
			border-radius: 20px;
			background-color: $secondary-color;
			color: $lightest-color;
			display: inline-block;
			vertical-align: middle;
			overflow: hidden;
			padding: 0 15px;
			position: relative;

			&[data-disabled="true"] {
				opacity: .3;
				pointer-events: none;
			}

			&:hover {
				opacity: .7;
			}
		}
	}

	&__prev {
		order: 1;

		::before {
			content: none;
			font-size: calc(14 / 18 * 1em);
			display: inline-block;
			vertical-align: middle;
			color: $font-primary-color;
			padding-right: 5px;
		}
	}

	&__next {
		order: 3;

		::after {
			content: none;
			font-size: calc(14 / 18 * 1em);
			display: inline-block;
			vertical-align: middle;
			color: $font-primary-color;
			padding-left: 5px;
		}
	}

	&__numbers {
		display: flex;
		align-items: center;
		font-weight: bold;
		list-style: none;
		margin: 0 25px;
		order: 2;
		padding: 0;

		@media (--sm-lte) {
			display: none;
		}
	}

	&__number {
		width: 36px;
    	height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		list-style: none;
		margin: 0 2.5px;
		border-radius: 20px;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			color: $secondary-color;
		}

		// 🐲 baserCMSの仕様上 マルチクラスでしかカレントを定義できない
		&--current { // stylelint-disable-line selector-class-pattern
			border: 2px solid $primary-color;
			background-color: $green-color05;

			&:hover { // stylelint-disable-line selector-class-pattern
				padding-bottom: 0;
			}

			a {
				color: $green-color03;
			}
		}

		a {
			display: block;
			padding: 0 .3em;
			text-align: center;
			line-height: 1;

			&::after {
				display: none;
			}
		}
	}
}
