.c-parts-button {
	margin: 80px auto 0;
	width: calc(90 / 100 * 100%);
	text-align: center;

	@media (--sm-lte) {
		margin: 50px auto 0;
	}

	&__back{
		a {
			display: block;
			width: 300px;
			margin: 0 auto;
			padding: 20px 35px;
			background-color: $green-color04;
			border-radius: 50px;
			color: $lightest-color;
			text-decoration: none;
			position: relative;

			@media (--sm-lte) {
				width: 100%;
				padding: 18px 30px;
				font-size: 1.4rem;
			}

			&::after {
				border: 2px solid $lightest-color;
				border-width: 2px 2px 0 0;
				content: "";
				display: block;
				width: 10px;
				height: 10px;
				margin-top: -5px;
				position: absolute;
				left: 20px;
				top: 50%;
				transform: rotate(-135deg);
			}
		}
	}
}
