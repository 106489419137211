body {
	color: $font-primary-color;
	font-family: $font-family-primary, sans-serif;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-feature-settings: "palt";
	margin: 0;
	padding: 0;
	word-break: break-all;
}
