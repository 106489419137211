.c-nav-global {
	display: flex;
	height: auto;
	min-width: 1000px;

	@media (--sm-lte) {
		display: none;
		position: relative;
		z-index: 106;
		box-shadow: 0 10000px 0 10000px rgba(0,0,0,.7);
		min-width: auto;
	}

	&__body {
		width: 1000px;
		display: flex;
		margin: 0 auto;
		min-height: 62px;
		padding: 0 10px 0 0px;

		@media (--sm-lte) {
			display: block;
			position: absolute;
			top: 0;
			width: 100%;
			padding: 30px 1em 20px;
			background-color: $primary-color;

				ul {
						list-style: none;

					li {
						a {
							text-decoration: none;
						}
					}
				}
			}
	}

	&__list {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		width: 100%;
		margin: 0;
		padding: 0;
		line-height: 1;

		& + & {
			display: none;

			@media (--sm-lte) {
				display: block;
			}
		}

		@media (--sm-lte) {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			padding: 0;
			margin: 0;
		}
	}

	&__item {
		list-style: none;
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 0 5px 0 11px;
		font-weight: 700;
		font-size: 1.5rem;
		font-family: $font-family-primary02;
		font-feature-settings: normal;
		letter-spacing: .05em;
		text-align: center;
		width: calc(1 / 5 * 100%);


		@media (--sm-lte) {
			display: block;
			width: 100%;
			padding: 15px;
			text-align: left;
			border-bottom: 1px solid $green-color15;
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			width: 1px;
			height: 12px;
			background: $primary-color;
			transform: skewX(150deg) translateY(-50%);
		}

		&:first-child {
			padding-left: 0;

			@media (--sm-lte) {
				padding-left: 15px;
			}
		}

		&:last-child {
			&::after {
				content: none;
			}
		}

		> a {
			color: $darkest-color03;
			text-decoration: none;
			line-height: 1.2;

			@media (--sm-lte) {
				color: $lightest-color;
				display: block;
				font-weight: 700;
				font-size: $base-font-size;;
				position: relative;
				line-height: 1.7;
				letter-spacing: normal;
				padding-right: 40px;

				&::after {
					border: 2px solid currentColor;
					border-width: 2px 2px 0 0;
					content: "";
					display: block;
					height: 12px;
					margin-top: -5px;
					position: absolute;
					right: 1.3em;
					top: 50%;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					width: 12px;
				}
			}

			&:hover {
				color: $secondary-color;
			}

			> span {

				@media (--sm-lte) {
					border-left: none;
				}
			}
		}

		&-other {
			position: relative;
			width: 100px;
			padding: 0;
			background-color: $green-color12;

			@media (--sm-lte) {
				display: none;
			}

			span {
				display: block;
				text-align: center;

				&::after {
					content: "";
					position: absolute;
					left: 50%;
					bottom: 8px;
					width: 16px;
					height: 7px;
					transform: translateX(-50%);
					background-image: url("/img/common/icon-header-arrow-green.png");
					background-size: 16px 7px;
					background-repeat: no-repeat;
				}

				a {
					display: block;
					width: 100%;
					padding: 12px 0 18px;
					color: $green-color13;
					font-size: calc(13 / 16 * 1em);
					font-family: $font-family-primary02;
					letter-spacing: .05em;
					line-height: 1.4;
					text-decoration: none;

					&:hover {
						opacity: .6;
					}
				}
			}

			&-sub {
				display: none;
				position: relative;

				&-open {
					display:block !important;
				}
			}

			&-sublist {
				background: rgba(255,255,255,0.9);
				list-style: none;
				margin: 0;
				padding: 10px 15px 25px;
				position: absolute;
				right: -15px;
				text-align: center;
				width: 160px;

				li {
					a {
						color: $gray-color11;
						display: block;
						font-size: calc(13 / 16 * 1em);
						padding: 12px 8px 10px;
						height: auto;
						line-height: 1.6;
						letter-spacing: .2px;
						text-align: left;
						text-decoration: none;
						border-bottom: 1px solid $green-color13;
						transition: 0.3s;

						&:hover {
							opacity: .6;
						}
					}
				}
			}
		}

		&__sp {
			display: none;

			@media (--sm-lte) {
				display: block;
				width: 100%;
				padding: 15px;
				text-align: left;
				border-bottom: 1px solid $green-color15;
			}
			a {
				@media (--sm-lte) {
					color: $lightest-color;
					display: block;
					font-weight: 700;
					font-size: $base-font-size;;
					position: relative;
					line-height: 1.7;
					letter-spacing: normal;
					padding-right: 40px;
	
					&::after {
						border: 2px solid currentColor;
						border-width: 2px 2px 0 0;
						content: "";
						display: block;
						height: 12px;
						margin-top: -5px;
						position: absolute;
						right: 1.3em;
						top: 50%;
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
						width: 12px;
					}
				}
			}
			&-sub {
				display: none;

				@media (--sm-lte) {
					margin-top: 20px;
					display: block;
					padding: 10px 15px;
					text-align: left;
					list-style: none;

					&:after {
						content:none;
					}
				}

				> a {
					@media (--sm-lte) {
						color: $green-color27;
						display: block;
						font-weight: 700;
						text-indent: -1em;
						padding-left: 1em;
						word-break: break-all;
						line-height: 1.7;
					}

					&:before {
						@media (--sm-lte) {
							content: "";
							display: inline-block;
							vertical-align: middle;
							margin: -.4rem 0 0 -11px;
							width: 23px;
							height: 23px;
							background-image: url(/img/common/icon-gnav-sp-sub.png);
							background-repeat: no-repeat;
							background-position: 0 50%;
							background-size: 11px;
						}
					}
				}
			}

			&-sub + &-sub {
				margin-top: 0;
			}

			&-contact {
				display: none;

				@media (--sm-lte) {
					display: block;
					padding: 0 15px;
					text-align: left;
					margin: 15px 0;

					&::after {
						content:none;
					}
				}

				> a {
					@media (--sm-lte) {
						display: block;
						background: $green-color18;
						border: 2px solid $green-color19;
						margin: 0 -15px;
						padding: 15px;
						text-align: center;
						width: calc(100% + 30px);
						color: $lightest-color;
						text-decoration: none;
						padding: 15px;
						line-height: 1.7;
						font-weight: 700;
					}
				}
			}
		}
	}

	&__sub-nav {
		position: absolute;
		left: 0;
		width: 100%;
		background: $secondary-color;

		@media (--sm-lte) {
			position: static;
		}

		> ul {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-around;

			@media (--sm-lte) {
				display: block;
				padding: 0;
			}

			> li {
				list-style: none;

				@media (--sm-lte) {
					border-bottom: 1px solid $border-primary-color;
				}

				> a {
					color: $lightest-color;
					text-decoration: none;
					padding: 15px;
					width: 100%;
					display: block;

					@media (--sm-lte) {
						text-align: center;
						padding: 10px 4%;
					}

					&:hover {
						opacity: .7;
					}
				}
			}
		}
	}

	&-sp {
		display: none;

		@media (--sm-lte) {
			position: relative;
			z-index: 106;
			box-shadow: 0 10000px 0 10000px rgba(0,0,0,.7);
		}

		&__list {
			display: none;

			@media (--sm-lte) {
			display: block;
			position: absolute;
			top: 0;
			width: 100%;

				ul {
						list-style: none;

					li {
						a {
							text-decoration: none;
						}
					}
				}
			}
		}

		&__open-nav {
			@media (--sm-lte) {
				display: block;
				transition: 300ms;
			}
		}

		&__list-items {
			@media (--sm-lte) {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				background-color: $primary-color;
				padding: 30px 1em 10px;
				margin: 0;

			li {
				padding: 15px;
				text-align: left;
				border-bottom: 1px solid $green-color15;

				&:last-child {
						border-bottom: none;

					a {
							background: $green-color18;
							border: 2px solid $green-color19;
							margin: 10px -15px;
							padding: 15px;
							text-align: center;
							width: calc(100% + 30px);

							&::after {
								content:none;
							}
						}
					}
				}
			}

			a {
				@media (--sm-lte) {
					color: $lightest-color;
					display: block;
					font-weight: bold;
					font-size: calc(16 / 16 * 1em);
					position: relative;

					&::after {
						border: 2px solid currentColor;
						border-width: 2px 2px 0 0;
						content: "";
						display: block;
						height: 12px;
						margin-top: -5px;
						position: absolute;
						right: 1.3em;
						top: 50%;
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
						width: 12px;
					}
				}
			}
		}
	}
}
