.c-list-news {
	margin-bottom: 50px;

	@media (--sm-lte) {
		margin-bottom: 30px;
	}

	ul {
		padding: 0;
	}

	li {
		margin-bottom: 5px;
		list-style: none;
		//background-color: $yellow-color01;
		border-bottom: 1px solid $green-color08;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-start;
			padding: 20px 30px 20px 10px;
			color: $darkest-color;
			text-decoration: none;
			position: relative;

			@media (--sm-lte) {
				display: block;
				padding: 20px 40px 20px 20px;
			}

			&:hover {
				opacity: .7;
				transition: 300ms;
			}

			&::after {
				border: 2px solid $green-color11;
				border-width: 2px 2px 0 0;
				content: "";
				display: block;
				width: 10px;
				height: 10px;
				margin-top: -5px;
				position: absolute;
				right: 5px;
				top: 50%;
				transform: rotate(45deg);
			}

			p {
				margin: 0;
				vertical-align: top;
			}

		}
	}

	&__sub {
		flex-basis: 30%;
		display: table;

		@media (--sm-lte) {
			width: auto;
		}

		&-date {
			display: table-cell;
			width: 110px;
			padding-right: 15px;
			color: $green-color11;
			font-family: $font-family-list;
			font-size: 20px;
			line-height: 1.2;

			@media (--sm-lte) {
				width: auto;
				padding: 0 10px 0 0;
			}
		}

		&-category {
			display: table-cell;
			padding: 2px 25px 0 0;
			font-size: 1.2rem;

			@media (--sm-lte) {
				padding: 0;
				text-align: left;
			}

			span {
				display: block;
				padding: 3px 10px 2px;
				line-height: 1.4;
				background-color: $green-color14;
				color: $lightest-color;
				text-align: center;

				@media (--sm-lte) {
					display: inline-block;
					text-align: left;
				}
			}
		}
	}

	&__title {
		flex-basis: 70%;

		@media (--sm-lte) {
			width: 100%;
			padding-top: 5px;
		}

		strong {
			color: $yellow-color02;
			font-size: 1.3rem;

			@media (--sm-lte) {
				display: inline-block;
			}
		}
	}

	&__new {
		display: inline-block;
		padding: 3px 15px 2px;
		text-align: center;
		background-color: $yellow-color03;
		font-size: 1.3rem;
		line-height: 1.4;
		border-radius: 20px;
		color: $lightest-color;
		font-family: $font-family-list;
		vertical-align: middle;
		margin-left: 3px;
	}
}
