@charset "utf-8";

@import "theme/color";
@import "theme/dimension";
@import "theme/font";
// @import "theme/duration";
// @import "theme/keyframe";
@import "base/reset";
@import "base/root";
@import "general/all";
@import "general/body";
@import "general/button";
@import "general/print";
// @import "general/a";
@import "component/page-home";
@import "component/page-sub";
@import "component/header";
@import "component/footer";
@import "component/nav-global";
@import "component/nav-local";
@import "component/nav-sitemap";
@import "component/nav-breadcrumb";
@import "component/list-banner";
@import "component/list-category";
@import "component/list-tag";
@import "component/list-news";
@import "component/list-dblog";
@import "component/title-page";
@import "component/hero";
@import "component/pagination";
@import "component/card";
@import "component/content-index";
@import "component/content-main";
@import "component/parts-button";
@import "component/separate-out";

// SASSファイルを使用する場合は有効にする（gulpタスクも有効にする）
// @import "lib/sassy/index";
